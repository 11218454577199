import React from 'react';
import Log from '../utils/log.js';
import SmartLogin from './Login.js';
import Helper from '../utils/helper.js';
import { Layout, 
  Input, 
  Menu, 
  Button, 
  Icon, 
  DatePicker, 
  Upload, 
  Form, 
  message, 
  Tooltip, 
  Anchor, 
  List, 
  Dropdown, 
  Checkbox, 
  Modal, 
  Tabs, 
} from 'antd';
import moment from 'moment'
import ManageUrls from "../endpoints/manage_urls.js";
import {
  fetchUserDetails,
  requestAccountUpdate,
  requestUploadFile,
  requestCardDetails,
  showCardList,
  deleteCard,
  requestSubmitCode,
  requestPhoneConfirmation,
  requestEmailConfirmation,
  requestLimit,
  requestCountries,
  requestSubmitSendingLimitSelection,
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import '../styles/user.css'
import { Doughnut } from 'react-chartjs-2';
import baseUrlOptions from '../endpoints/base_url_options.js';
import exampleSelfieImage from '../images/exmaple-selfie.jpg'

const FormItem = Form.Item;
const confirm = Modal.confirm;
const TabPane = Tabs.TabPane;
const { Content } = Layout;
const { Link } = Anchor;
const TAG = "SmartUserProfile";

class UpdateUserProfile extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));

    var cookies = {};
    var defaultActiveKey = "1"

    if (props.cookies !== undefined) {
      cookies = props.cookies;
    }

    if (props.location.state) {
      if (props.location.state.defaultActiveKey) {
        defaultActiveKey = props.location.state.defaultActiveKey
      }
    }

    this.state = {
      cookies: cookies,
      headerKey: "1",
      defaultActiveKey: defaultActiveKey
    }
  }

  renderBody() {
    return (this.renderContent())
  }

  reloadUser = () => {
    Log.d(TAG, "reloadUser() -- called ..");
    // if (this.props.cookies.cookies.access) {
    if (this.props.authReducer.accessToken) {
      // this.props.fetchUserDetails(this.props.cookies, this.props.cookies.cookies.access, false, "");
      this.props.fetchUserDetails(this.props.cookies, this.props.authReducer.accessToken, false, "");
    }
  }

  onLogin = () => {
    Log.d(TAG, "onLogin() -- called ..");
    window.location.reload();
  }

  onLoginError = () => {
    Log.d(TAG, "onLoginError() -- called ..");
  }

  loadSelfieTab = () => {
    Log.d(TAG, "loadSelfieTab() -- called ..");
    this.setState({
      defaultActiveKey: "4"
    })
    window.scrollTo(0, 0)
  }

  loadUploadDocsTab = () => {
    Log.d(TAG, "loadSelfieTab() -- called ..");
    this.setState({
      defaultActiveKey: "3"
    })
    window.scrollTo(0, 0)
  }

  markRequired = (<span style={{ color: 'red' }}>* </span>);

  render() {
    Log.d(TAG, "render() -- called ..");
    Log.d(TAG, "render() -- current props=" + JSON.stringify(this.props));
    // if (this.props.cookies.cookies.access) {
    if (!!this.props.authReducer.accessToken) {
      return (
        <div className="userprofile-content-container">
          {this.renderWholeContent()}
        </div>
      )
    } else {
      return (
        <SmartLogin {... this.props} onLogin={this.onLogin} onLoginError={this.onLoginError} />
      )
    }
  }

  renderWholeContent() {
    return (
      <Layout>
        {this.renderBody()}
      </Layout>
    )
  }

  renderContent() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      Log.d(TAG, "renderContent() -- render user")
      return this.renderWithContent()
    }
    Log.d(TAG, "renderContent() -- render no user")
    return this.renderNoContent()
  }

  onChange = (activeKey) => {
    this.setState({
      defaultActiveKey: activeKey
    })
  }

  renderWithContent() {
    Log.d(TAG, "renderWithContent() -- user=" + JSON.stringify(this.props.userReducer.user))
    return (
      <Content>
        <Tabs defaultActiveKey="1" activeKey={this.state.defaultActiveKey} onChange={this.onChange}>
          <TabPane tab="Basic Information" key="1">
            <div className="profile-box">
              <div className="profile-box-left">
                <WrappedBasicInformationForm {...this.props} />
              </div>
              <div className="profile-box-center"></div>
              <div className="profile-box-right">
                {this.renderPasswordForm()}
              </div>
            </div>
          </TabPane>
          {this.renderPhoneVerificationTab()}
          {this.renderEmailTab()}
          {this.renderExtendedInformationTab()}
          {this.renderCardsTab()}
          {this.renderDocumentManagementForm()}
          {Helper.baseCountryIsSA && this.renderSelfieForm()}
          {this.renderSelectSendingLimitsTab()}
        </Tabs>

      </Content>
    )
  }

  renderLinkPassword() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified) {
        return (<Link href="#password-management" title="Change password" />)
      }
    }
    return (<span />)
  }

  renderPasswordForm() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      /*if (this.props.userReducer.user.data.attributes.source_of_registration === "A") {
        if (this.props.userReducer.user.data.attributes.password_reset_needed || this.props.userReducer.user.data.attributes.password_expired || this.props.userReducer.user.data.attributes.phone_verified) {
          return (<WrappedPasswordForm {...this.props} />)
        } else if (this.props.userReducer.user.data.attributes.password_expired) {
          return (<WrappedPasswordForm {...this.props} />)
        }
      } else {
        if (Helper.acceptedSelfiesCount(this.props.userReducer.user) > 0) {
          if (this.props.userReducer.user.data.attributes.password_reset_needed || this.props.userReducer.user.data.attributes.phone_verified) {
            return (<WrappedPasswordForm {...this.props} />)
          }
        } else if (this.props.userReducer.user.data.attributes.password_expired) {
          return (<WrappedPasswordForm {...this.props} />)
        }
      }*/
      return (<WrappedPasswordForm {...this.props} />)
    }
    return (<span />)
  }

  renderLinkEmail() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified) {
        if (!this.props.userReducer.user.data.attributes.email_verified && this.validateEmail(this.props.userReducer.user.data.attributes.email)) {
          return (<Link href="#email-verification" title="Verify Email" />)
        }
      }
    }
    return (<span />)
  }

  validateEmail(email) {
    if (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    return false;
  }

  renderEmailTab() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (Helper.acceptedSelfiesCount(this.props.userReducer.user) > 0) {
        if (this.props.userReducer.user.data.attributes.phone_verified && !this.props.userReducer.user.data.attributes.password_expired) {
          if (!this.props.userReducer.user.data.attributes.email_verified && this.validateEmail(this.props.userReducer.user.data.attributes.email)) {
            return (<TabPane tab="Verify Email" key="6">
              <WrappedEmailVerificationForm {...this.props} />
            </TabPane>)
          }
        }
      }
    }
  }

  renderSelectSendingLimitsTab() {
    // if (Object.keys(this.props.userReducer.user).length > 0) {
    //   if (this.props.userReducer.user.data.attributes.phone_verified && !this.props.userReducer.user.data.attributes.password_reset_needed && !this.props.userReducer.user.data.attributes.password_expired) {
    //     return (<TabPane tab="Select Sending Limits" key="9">
    //       <WrappedSelectSendingLimitsForm {...this.props} loadUploadDocsTab={this.loadUploadDocsTab} />
    //     </TabPane>)
    //   }
    // }
  }

  renderCardsTab() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (Helper.acceptedSelfiesCount(this.props.userReducer.user) > 0) {
        if (this.props.userReducer.user.data.attributes.phone_verified && !this.props.userReducer.user.data.attributes.password_expired) {
          return (<TabPane tab="Saved Cards" key="8">
            <SavedCardList {...this.props} reloadUser={this.reloadUser} />
          </TabPane>)
        }
      }
    }
  }

  renderPhoneVerificationTab() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified === false && this.props.userReducer.user.data.attributes.password_reset_needed === false && this.props.userReducer.user.data.attributes.password_expired === false) {
        return (
          <TabPane tab="Verify Phone Number" key="7">
            {this.renderWrapPhoneVerification()}
          </TabPane>
        )
      }
    }
  }

  renderEmailForm() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.email_verified) {
        if (!this.props.userReducer.user.data.attributes.email_verified && this.validateEmail(this.props.userReducer.user.data.attributes.email)) {
          return (<WrappedEmailVerificationForm {...this.props} />)
        }
      }
    }
    return (<span />)
  }

  renderLinkSelfie() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified) {
        return (<Link href="#selfie" title="Upload your selfie" />)
      }
    }
    return (<span />)
  }

  renderSelfie() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      return (<WrappedSelfieForm {...this.props} />)
    }
    return (<span />)
  }

  renderLinkLimit() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified) {
        return (<Link href="#fica-limit" title="Limit" />)
      }
    }
    return (<span />)
  }

  renderLimit() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified) {
        return (<FICALimit {...this.props} />)
      }
    }
    return (<span />)
  }

  renderLinkBreadWinner() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified) {
        return (<Link href="#bread-winner-policy" title="Breadwinner Policy" />)
      }
    }
    return (<span />)
  }

  renderBreadWinnerForm() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified) {
        return (<WrappedBreadWinnerPolicyForm {...this.props} />)
      }
    }
    return (<span />)
  }

  renderLinkExtendedInformation() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified && !this.props.userReducer.user.data.attributes.password_expired) {
        return (<Link href="#extended-information" title="Extended information" />)
      }
    }
    return (<span />)
  }

  renderExtendedInformationTab() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified && !this.props.userReducer.user.data.attributes.password_expired) {
        if (Helper.acceptedSelfiesCount(this.props.userReducer.user) > 0) {
          return (<TabPane tab="Extended Information" key="2">
            {this.renderExtendedInformationForm()}
          </TabPane>)
        }
      }
    }
  }

  renderExtendedInformationForm() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      return (<WrappedExtendedInformationForm {...this.props} />)
    }
    return (<span />)
  }

  renderLinkSavedCards() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified) {
        return (<Link href="#saved-cards" title="Saved cards" />)
      }
    }
    return (<span />)
  }

  renderSavedCards() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified) {
        return (<SavedCardList {...this.props} />)
      }
    }
    return (<span />)
  }

  renderLinkPhoneVerification() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified === false) {
        return (<Link href="#phone-verification" title="Verify phone" />)
      }
    }
    return (<span />)
  }

  renderWrapPhoneVerification() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified === false) {
        return (<WrappedPhoneVerificationForm {...this.props} />)
      }
    }
    return (<span />)
  }

  renderLinkDocumentManagement() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      return (<Link href="#document-management" title="Upload documents" />)
    }
    return (<span />)
  }

  renderDocumentManagementForm() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified && !this.props.userReducer.user.data.attributes.password_reset_needed && !this.props.userReducer.user.data.attributes.password_expired) {
        return (
          <TabPane tab="Upload Documents" key="3">
            <div className="documents-box">
              <h3 className="user-title" id="document-management">Upload Documents</h3>
              <hr></hr>
              <Tabs type="card">
                <TabPane tab="Proof of ID" key="1">
                  <br />
                  <WrappedDocumentManagementForm {...this.props} loadSelfieTab={this.loadSelfieTab} /> {/* NOTE: loadSelfieTab={this.loadSelfieTab} is commented out in ComponentUpdate lifecycle or not called at all */}
                </TabPane>
                {/* <TabPane tab="Proof of Address" key="2">
                  <br />
                  <WrappedAddressDocumentManagementForm {...this.props} loadSelfieTab={this.loadSelfieTab} />
                </TabPane>
                <TabPane tab="Proof of Income" key="3">
                  <br />
                  <WrappedIncomeDocumentManagementForm {...this.props} loadSelfieTab={this.loadSelfieTab} />
                </TabPane>
                <TabPane tab="Work Permit" key="4">
                  <br />
                  <WrappedWorkPermitDocumentManagementForm {...this.props} loadSelfieTab={this.loadSelfieTab} />
                </TabPane> */}
              </Tabs>
            </div>
          </TabPane>
        )
      }
    }
  }

  renderSelfieForm() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.phone_verified && !this.props.userReducer.user.data.attributes.password_reset_needed && !this.props.userReducer.user.data.attributes.password_expired) {
        return (
          <TabPane tab="Upload Selfie" key="4">
            {this.renderSelfie()}
          </TabPane>
        )
      }
    }
  }

  renderNoContent() {
    return (
      <Content className="user-content-container" />
    )
  }

  componentWillMount() {
    Log.d(TAG, "componentWillMount() -- called ..");
    // if (this.props.cookies.cookies.access !== undefined) {
    if (this.props.authReducer.accessToken !== '') {
      Log.d(TAG, "componentWillMount() -- request a user");
      // Log.d(TAG, "componentWillMount() -- access=" + this.props.cookies.cookies.access);
      Log.d(TAG, "componentWillMount() -- access=" + this.props.authReducer.accessToken);
      // Log.d(TAG, "componentWillMount() -- cookies=" + JSON.stringify(this.props.cookies.cookies.access));
      Log.d(TAG, "componentWillMount() -- cookies=" + JSON.stringify(this.props.authReducer.accessToken));
      // this.props.fetchUserDetails(this.props.cookies, this.props.cookies.cookies.access, false, null);
      this.props.fetchUserDetails(this.props.cookies, this.props.authReducer.accessToken, false, null);
    }
  }

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- called ..");
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      message.error("" + this.props.userReducer.error, 5)
    }
    if (this.props.bwPolicyReducer.error !== null && prevProps.bwPolicyReducer.error !== this.props.bwPolicyReducer.error) {
      message.error("Breadwinner Policy Error - " + this.props.bwPolicyReducer.error, 5)
    }

    if (this.props.userReducer.loginMessage !== null && prevProps.userReducer.loginMessage !== this.props.userReducer.loginMessage) {
      Log.d(TAG, "componentDidUpdate() -- show login .. ")
      Helper.clearCookies(this.props.cookies)
      this.props.history.push({
        pathname: "/login"
      });
    }

    if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
      Log.d(TAG, "componentDidUpdate() -- set success=" + this.props.userReducer.success)
      if (this.props.userReducer.success) {
        if (this.props.userReducer.success === "Account updated.") {
          message.error("Please complete the registration process to proceed.", 5);
          this.setState({
            defaultActiveKey: "3"
          })
          window.scrollTo(0, 0)
        } else {
          message.success("" + this.props.userReducer.success, 5)
        }
      }
    }

    if (prevProps.userReducer.reload !== this.props.userReducer.reload) {
      if (this.props.userReducer.reload) {
        // if (this.props.cookies.cookies.access !== undefined) {
        if (this.props.authReducer.accessToken !== '') {
          // this.props.fetchUserDetails(this.props.cookies, this.props.cookies.cookies.access, false, "Account updated.");
          this.props.fetchUserDetails(this.props.cookies, this.props.authReducer.accessToken, false, "Account updated.");
        }
      }
    }

    if (this.props.fileUploadReducer.success !== null && prevProps.fileUploadReducer.success !== this.props.fileUploadReducer.success) {
      if(this.props.fileUploadReducer.fileType === "proof of id") {
        if(Object.keys(this.props.userReducer.user.data.attributes.files).length > 0) {
          if(this.props.userReducer.user.data.attributes.files.SL != null) {
            if(this.props.userReducer.user.data.attributes.files.SL.A < 0) {
              this.loadSelfieTab();
            } 
          } else {
            this.loadSelfieTab();
          }
        } else {
          this.loadSelfieTab();
        }
      }
    }
  }
}

class BasicInformationForm extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "BasicInformationForm::constructor() -- props1=" + JSON.stringify(props));
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    var phonePrefix = getFieldDecorator('phonePrefix')(
      <div style={{ width: 45 }}>
        +{this.props.userReducer.user.data.attributes.mobile[0]}
      </div>
    );

    return (
      <Form className="user-body">
        <h3 className="user-title" id="basic-information">Basic Information</h3>
        <hr />
        <FormItem label="Status">
          {getFieldDecorator('user_status', {
            initialValue: Helper.showUserStatus(this.props.userReducer.user.data.attributes.user_status)
          })(
            <Input disabled={true} className="profile-tf-2 form-control" />
          )}
        </FormItem>
        <FormItem label="Sub status">
          {getFieldDecorator('sub_status', {
            initialValue: this.showSubStatus(this.props.userReducer.user.data.attributes.user_sub_status)
          })(
            <Input disabled={true} className="profile-tf-2 form-control" />
          )}
        </FormItem>
        <FormItem label="Customer Number/User Code">
          {getFieldDecorator('user_code', {
            initialValue: this.props.userReducer.user.data.attributes.code
          })(
            <Input disabled={true} className="profile-tf-2 form-control" />
          )}
        </FormItem>
        <FormItem label="Email Address">
          {getFieldDecorator('email', {
            rules: [{
              type: 'email', message: 'Please provide valid e-mail address',
            }],
            initialValue: this.props.userReducer.user.data.attributes.email
          })(
            <Input disabled={true} className="form-control" />
          )}
        </FormItem>
        <FormItem label="Phone Number">
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'Please provide your phone number' }],
            initialValue: this.props.userReducer.user.data.attributes.mobile[1]
          })(
            <Input addonBefore={phonePrefix} disabled={true} className="mobile-number" />
          )}
        </FormItem>
        <FormItem label="Gender">
          {getFieldDecorator('gender', {
            initialValue: this.props.userReducer.user.data.attributes.gender ? (this.props.userReducer.user.data.attributes.gender === "M" ? "Male" : "Female") : "Not Available"
          })(
            <Input disabled={true} className="form-control" />
          )}
        </FormItem>
      </Form>
    )
  }

  showLoyaltyLevel(loyaltyLevel) {
    if (loyaltyLevel === "P") {
      return "Platinum"
    } else if (loyaltyLevel === "G") {
      return "Gold"
    } else if (loyaltyLevel === "S") {
      return "Silver"
    } else if (loyaltyLevel === "B") {
      return "Basic"
    } else {
      return "Not Available"
    }
  }

  showSubStatus(subStatus) {
    // if (subStatus === "AL") {
    //   return "EcoCash Lite"
    // } else if (subStatus === "AF") {
    //   return "EcoCash Full"
    // } else {
    //   return "Not Available"
    // }
    switch (subStatus) {
      case "AL": return "EcoCash Lite";
      case "AF": return "EcoCash Full";
      case "A1": return "Product 1";
      case "A2": return "Product 2";
      case "A3": return "Product 3";
      case "A4": return "Product 4";
      default: return "Not Available";
    }
  }
}

class SavedCardList extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "SavedCardList::constructor() -- props1=" + JSON.stringify(props));

    this.state = {
      cards: [],
      loading: false
    }
  }

  componentDidMount() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      this.requestDetailsIfHasCards();
    }
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "SavedCardList::componentDidUpdate() -- called ..");

    if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
      if (this.props.userReducer.success === "Card deleted successfully") {
        this.props.reloadUser();
      }
    }

    if (prevProps.userReducer.cardList !== this.props.userReducer.cardList) {
      Log.d(TAG, "SavedCardList::componentDidUpdate() -- cards=" + JSON.stringify(this.props.userReducer.cardList))
      Log.d(TAG, "SavedCardList::componentDidUpdate() -- currentCardIndex=" + this.props.userReducer.currentCardIndex)
      Log.d(TAG, "SavedCardList::componentDidUpdate() -- cardList=" + this.props.userReducer.cardList.length)
      var relationships = this.props.userReducer.user.data.relationships;
      if (relationships.saved_cards) {
        if (relationships.saved_cards.data) {
          if (this.props.userReducer.currentCardIndex < relationships.saved_cards.data.length) {
            Log.d(TAG, "SavedCardList::componentDidUpdate() -- request now")
            Log.d(TAG, "SavedCardList::componentDidUpdate() -- card hh=" + relationships.saved_cards.data[this.props.userReducer.currentCardIndex].id)
            // this.props.requestCardDetails(relationships.saved_cards.data[this.props.userReducer.currentCardIndex].id, this.props.cookies.cookies.access, this.props.userReducer.currentCardIndex, this.props.userReducer.cardList);
            this.props.requestCardDetails(relationships.saved_cards.data[this.props.userReducer.currentCardIndex].id, this.props.authReducer.accessToken, this.props.userReducer.currentCardIndex, this.props.userReducer.cardList);
          } else {
            this.setState({
              loading: false,
              cards: this.props.userReducer.cardList
            })
          }
        }
      }
    }
  }

  render() {
    return (
      <div className="user-saved-cards-body">
        <h3 className="user-title" id="saved-cards">Saved Bank Cards</h3>
        <List
          loading={this.state.loading}
          itemLayout="horizontal"
          bordered={true}
          dataSource={this.state.cards}
          renderItem={card => (
            <List.Item className="user-saved-cards-item" actions={[
              <Button className="home-collect"
                style={{
                  backgroundColor: '#154989',
                  boxSizing: 'border-box',
                  color: 'white'
                }} onClick={(event) => { event.stopPropagation(); this.onDelete(card, this.props); }}>Delete</Button>
            ]}>
              <List.Item.Meta
                title={"Last digits: " + card.data.attributes.last_digits}
                description={"Card type: " + card.data.attributes.card_type} />
            </List.Item>
          )}
        />
      </div>
    )
  }

  onDelete = (card, props) => {
    Log.d(TAG, "onDelete() -- card=" + JSON.stringify(card));
    confirm({
      title: 'Are you sure remove card with last digits ' + card.data.attributes.last_digits + ' as your saved card?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        // if (props.cookies.cookies.access) {
        if (!!this.props.authReducer.accessToken) {
          // props.deleteCard(card.data.id, props.cookies.cookies.access)
          props.deleteCard(card.data.id, this.props.authReducer.accessToken)
        }
      },
      onCancel() { },
    });
  }

  requestDetailsIfHasCards() {
    var relationships = this.props.userReducer.user.data.relationships;
    if (relationships.hasOwnProperty("saved_cards")) {
      Log.d(TAG, "requestDetailsIfHasCards() -- has saved cards");
      if (relationships.saved_cards.data != null) {
        Log.d(TAG, "requestDetailsIfHasCards() -- data is not null");
        if (relationships.saved_cards.data.length > 0) {
          Log.d(TAG, "requestDetailsIfHasCards() -- no. of cards is greater than zero");
          this.setState({
            loading: true
          })
          this.props.showCardList(0, []);
          // this.props.requestCardDetails(relationships.saved_cards.data[0].id, this.props.cookies.cookies.access, 0, []);
          this.props.requestCardDetails(relationships.saved_cards.data[0].id, this.props.authReducer.accessToken, 0, []);
        }
      }
    }
  }
}

class PasswordForm extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "PasswordForm::constructor() -- props1=" + JSON.stringify(props));

    this.state = {
      confirmDirty: false,
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('new_password')) {
      callback('New password did not match to this value');
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm_new_password'], { force: true });
    }
    callback();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.requestChangePassword();
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className="user-body"
        onSubmit={this.handleSubmit.bind(this)}>
        <h3 className="user-title" id="password-management">Change Password</h3>
        <hr />
        <FormItem label="Current Password">
          {getFieldDecorator('current_password', {
            rules: [{
              required: true, message: 'Please provide your current password',
            }, {
              min: 8, message: 'Password should be minumum of 8 characters',
            }],
          })(
            <Input className="profile-tf-2 form-control" type="password" />
          )}
        </FormItem>
        <FormItem label="New Password">
          {getFieldDecorator('new_password', {
            rules: [{
              required: true, message: 'Please provide your new password',
            }, {
              validator: this.validateToNextPassword,
            }, {
              min: 8, message: 'New Password should be minumum of 8 characters',
            }],
          })(
            <Input className="profile-tf-2 form-control" type="password" />
          )}
        </FormItem>
        <FormItem label="Confirm New Password">
          {getFieldDecorator('confirm_new_password', {
            rules: [{
              required: true, message: 'Please provide confirm your new password',
            }, {
              validator: this.compareToFirstPassword,
            }, {
              min: 8, message: 'Confirm New Password should be minumum of 8 characters',
            }],
          })(
            <Input className="profile-tf-2 form-control" type="password" />
          )}
        </FormItem>
        <FormItem className="user-custom-btn-row">
          <Button className="user-custom-btn"
            style={{
              backgroundColor: '#154989',
              boxSizing: 'border-box',
              color: 'white',
              width: '100%'
            }} type="primary" htmlType="submit">CHANGE PASSWORD</Button>
        </FormItem>
      </Form>
    )
  }

  requestChangePassword() {
    Log.d(TAG, "PasswordForm::requestChangePassword() -- called ..");

    // var access = this.props.cookies.cookies.access
    var access = this.props.authReducer.accessToken
    Log.d(TAG, "PasswordForm::requestChangePassword() -- access=" + access);

    if (Object.keys(this.props.userReducer.user).length > 0 && !Helper.isEmpty(access)) {
      var attributesObj = {};
      attributesObj.password = this.props.form.getFieldValue('current_password')
      attributesObj.new_password = this.props.form.getFieldValue('new_password')

      var dataObj = {};
      dataObj.type = "passwords"
      dataObj.id = this.props.userReducer.user.data.id
      dataObj.attributes = attributesObj;

      var payloadObj = {};
      payloadObj.data = dataObj;

      Log.d(TAG, "PasswordForm::requestChangePassword() -- payload=" + JSON.stringify(payloadObj));
      this.props.requestAccountUpdate(this.props.cookies, this.props.userReducer.user, access, payloadObj, this.props.form.getFieldValue('new_password'));
    }
  }
}

class ExtendedInformationForm extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "ExtendedInformationForm::constructor() -- props1=" + JSON.stringify(props));

    this.state = {
      getFieldDecorator: this.props.form,
      maxBirthDate: moment().subtract(18, 'years'),
      genders: ["Male", "Female"],
      chosenGender: 0,
      editable: !props.userReducer.user.data.attributes.is_editable,
      idType: this.manageUrls.getBaseCountry() === baseUrlOptions.SOUTH_AFRICA ? this.getSAIDTypes() : this.getUKIDTypes(),
      chosenIDType: 0,
      chosenProvince: 0,
      provinces: [
        { "code": "EC", "value": "Eastern Cape" },
        { "code": "FS", "value": "Free State" },
        { "code": "GT", "value": "Gauteng" },
        { "code": "NL", "value": "KwaZulu Natal" },
        { "code": "LP", "value": "Limpopo" },
        { "code": "MP", "value": "Mpumalanga" },
        { "code": "NW", "value": "North West" },
        { "code": "NC", "value": "Northern Cape" },
        { "code": "WC", "value": "Western Cape" }
      ],
      homeWallet: false
    }
  }

  manageUrls = new ManageUrls()
  baseCountryIsSA = () => this.manageUrls.baseCountryIsSA()

  setNoErrorID() {
    this.props.form.setFields({
      'id_document_number': {
        value: this.props.form.getFieldValue('id_document_number'),
        errors: null,
      }
    });
  }

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- props=" + JSON.stringify(this.props));
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (this.props.userReducer.user.data.attributes.gender !== null) {
        Log.d(TAG, "componentDidMount() -- gender1=" + this.props.userReducer.user.data.attributes.gender);
        this.setState({
          chosenGender: this.props.userReducer.user.data.attributes.gender === "M" ? 0 : 1,
        })
      } else {
        this.setState({
          chosenGender: 0,
        })
      }

      if (this.props.userReducer.user.data.attributes.id_verification_type !== null) {
        for (var idVTypeIndex = 0; idVTypeIndex < this.state.idType.length; idVTypeIndex++) {
          if (this.state.idType[idVTypeIndex].type === this.props.userReducer.user.data.attributes.id_verification_type) {
            this.setState({
              chosenIDType: idVTypeIndex
            })
          }
        }
      }

      if (this.props.userReducer.user.data.attributes.county !== null) {
        if (this.baseCountryIsSA()) {
          for (var provincesIndex = 0; provincesIndex < this.state.provinces.length; provincesIndex++) {
            if (this.state.provinces[provincesIndex].code === this.props.userReducer.user.data.attributes.county || this.state.provinces[provincesIndex].value === this.props.userReducer.user.data.attributes.county) {
              this.setState({
                chosenProvince: provincesIndex
              })
            }
          }
        } else {
          this.setState({
            chosenProvince: this.props.userReducer.user.data.attributes.county
          })
        }
      }

      this.props.form.setFieldsValue({
        id_document_type: this.state.chosenIDType
      })
      if (this.props.userReducer.user.data.attributes.id_document_number !== null) {
        if (this.props.userReducer.user.data.attributes.id_verification_type !== null) {
          for (var idTypeIndex = 0; idTypeIndex < this.state.idType.length; idTypeIndex++) {
            if (this.state.idType[idTypeIndex].type === this.props.userReducer.user.data.attributes.id_verification_type) {
              this.props.form.setFieldsValue({
                id_document_number: this.props.userReducer.user.data.attributes.id_document_number
              })
            }
          }
        }
      }

      if (this.props.userReducer.user.data.relationships.id_nationality.data !== null) {
        this.props.form.setFieldsValue({
          nationality: this.props.countryReducer.countries[this.props.userReducer.user.data.relationships.id_nationality.data.id]
        })
      } else {
        this.props.form.setFieldsValue({
          nationality: "Not Available"
        })
      }
      this.setState({
        homeWallet: this.props.userReducer.user.data.attributes.mobile_wallet_auto_create
      })
      this.props.requestCountries();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.countryReducer.countries.length > 0 && this.props.countryReducer.countries !== prevProps.countryReducer.countries) {
      if (this.props.userReducer.user.data.relationships.id_nationality.data) {
        for (var index in this.props.countryReducer.countries) {
          if (this.props.countryReducer.countries[index].id === this.props.userReducer.user.data.relationships.id_nationality.data.id) {
            this.props.form.setFieldsValue({
              nationality: this.props.countryReducer.countries[index].attributes.name
            })
          }
        }
      } else {
        this.props.form.setFieldsValue({
          nationality: "Not Available"
        })
      }
    }
  }

  getSAIDTypes() {
    return [
     {
       "type": "SI",
       "name": "South African ID"
     },
     {
       "type": "FP",
       "name": "Foreign Passport"
     },
     {
       "type": "AP",
       "name": "Asylum Permit"
     },
     {
       "type": "FI",
       "name": "Foreign ID"
     }
   ]; 
  }

  getUKIDTypes() {
   return [
     {
       "type": "PP",
       "name": "Passport"
     },
     {
       "type": "DL",
       "name": "UK Driving licence"
     },
     {
       "type": "HI",
       "name": "UK Home office ID"
     },
     {
       "type": "NC",
       "name": "National ID Card"
     }
   ];
  }
    
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Log.d(TAG, 'Received values of form: ', values);
        if (values.id_document_number && Number(this.state.chosenIDType) === 0) {
          if (this.checkIDNumber(values.id_document_number)) {
            this.updateProfile(err, values);
          } else {
            Helper.setError(this.props.form, "id_document_number", 'This field has invalid SA ID number.');
          }
        } else {
          this.updateProfile(err, values);
        }
      }
    });
  }

  updateProfile(err, values) {
    this.setNoErrorID();
    var attributesObj = {}
    attributesObj.notify_promotions = values.send_promotions;
    if (this.props.userReducer.user.data.attributes.mobile_wallet_auto_create !== null || this.props.userReducer.user.data.attributes.mobile_wallet_auto_create !== undefined) {
      attributesObj.mobile_wallet_auto_create = this.state.homeWallet;
    }
    if (this.props.userReducer.user.data.attributes.is_editable) {
      attributesObj.first_name = values.first_name;
      attributesObj.middle_name = values.middle_name;
      attributesObj.last_name = values.last_name;
      attributesObj.mobile = this.props.userReducer.user.data.mobile;
      attributesObj.email = this.props.userReducer.user.data.email;

      if (this.state.chosenGender === 0) {
        attributesObj.gender = "M"
      } else {
        attributesObj.gender = "F"
      }
      attributesObj.mobile_wallet_msisdn = this.props.userReducer.user.data.mobile_wallet_msisdn;
      attributesObj.city = values.city_town;
      attributesObj.county = this.state.provinces[this.state.chosenProvince].code; //values.state_province;
      attributesObj.birth_date = values.birth_date.format("YYYY-MM-DD");
      if (values.postal_code) {
        attributesObj.postcode = values.postal_code;
      } else {
        attributesObj.postcode = "0000";
      }
      attributesObj.address_line_1 = values.address_line_1;
      attributesObj.address_line_2 = values.address_line_2;
      attributesObj.street = null;
      attributesObj.building_no = null;
      attributesObj.id_verification_type = this.state.idType[this.state.chosenIDType].type;
      attributesObj.id_document_number = values.id_document_number;
      attributesObj.flat = null;
      attributesObj.occupation = values.occupation ? values.occupation : "Unemployed"
    }

    var dataObj = {};
    dataObj.type = "accounts";
    dataObj.id = this.props.userReducer.user.data.id;
    dataObj.attributes = attributesObj;

    var payloadObj = {};
    payloadObj.data = dataObj;
    Log.d(TAG, "handleSubmit() -- payload=" + JSON.stringify(payloadObj));
    Log.d(TAG, "handleSubmit() -- state=" + JSON.stringify(this.state));
    Log.d(TAG, "handleSubmit() -- props=" + JSON.stringify(this.props));

    // this.props.requestAccountUpdate(this.props.cookies, this.props.userReducer.user, this.props.cookies.cookies.access, payloadObj, null);
    this.props.requestAccountUpdate(this.props.cookies, this.props.userReducer.user, this.props.authReducer.accessToken, payloadObj, null);
  }

  disabledDate = (current) => {
    Log.d(TAG, "disabledDate() -- current=" + current);
    if (current) {
      var hundredRangeMet = current < moment().subtract(100, 'years').endOf('day');
      var eighteenRangeMet = current > this.state.maxBirthDate.endOf('day');
      var rangeMet = hundredRangeMet || eighteenRangeMet
      return rangeMet;
    }
    return false;
  }

  render() {
    Log.d(TAG, "ExtendedInformationForm::render() -- props=" + JSON.stringify(this.props));
    const { getFieldDecorator } = this.props.form;
    var countryName = ""
    for (var i = 0; i < this.props.userReducer.user.included.length; i++) {
      if (this.props.userReducer.user.included[i].type === "countries") {
        countryName = this.props.userReducer.user.included[i].attributes.name
      }
    }

    return (
      <Form className="profile-body"
        onSubmit={this.handleSubmit.bind(this)}>
        {this.renderHWStatus(getFieldDecorator)}
        <h3 className="user-title" id="extended-information">Extended Information</h3>
        <hr></hr>
        <div className="user-item-2">
          <FormItem className="user-custom-row" label="First name">
            {getFieldDecorator('first_name', {
              rules: [{ required: true, message: 'Please provide your first name' }],
              initialValue: this.props.userReducer.user.data.attributes.first_name
            })(
              <Input disabled={!this.props.userReducer.user.data.attributes.is_editable} className="profile-tf-2 form-control" />
            )}
          </FormItem>
          <FormItem className="user-custom-row" label="Middle Name">
            {getFieldDecorator('middle_name', {
              initialValue: this.props.userReducer.user.data.attributes.middle_name
            })(
              <Input disabled={!this.props.userReducer.user.data.attributes.is_editable} className="profile-tf-2 form-control" />
            )}
          </FormItem>
        </div>
        <div className="user-item-2">
          <FormItem className="user-custom-row" label="Surname">
            {getFieldDecorator('last_name', {
              rules: [{ required: true, message: 'Please provide your last name' }],
              initialValue: this.props.userReducer.user.data.attributes.last_name
            })(
              <Input disabled={!this.props.userReducer.user.data.attributes.is_editable} className="profile-tf-2 form-control" />
            )}
          </FormItem>
          <FormItem className="user-custom-row" label="Gender">
            {getFieldDecorator('gender', {
              initialValue: this.state.chosenGender
            })(
              <Dropdown
                className="user-tf-2"
                trigger={['click']}
                disabled={!this.props.userReducer.user.data.attributes.is_editable}
                overlay={this.renderGenderParent()}>
                <div className="dropdown-item">
                  <div>
                    {this.renderGenderName()}
                  </div>
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>
        </div>
        <div className="user-item-2">
          <FormItem className="user-custom-row"
            label={(
              <span>
                Birth Date
                <Tooltip title="You must be 18 and not greater than 100 years old">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            )}>
            {getFieldDecorator('birth_date', {
              rules: [{ required: true, message: 'Please provide your birth date' }],
              initialValue: moment(this.props.userReducer.user.data.attributes.birth_date, 'YYYY/MM/DD')
            })(
              <DatePicker
                allowClear={false}
                format={'YYYY/MM/DD'}
                className="su-tf-2"
                disabledDate={this.disabledDate}
                disabled={!this.props.userReducer.user.data.attributes.is_editable} />
            )}
          </FormItem>
          <FormItem className="user-custom-row" label="Country">
            {getFieldDecorator('country', {
              rules: [{ required: true, message: 'Please provide your country' }],
              initialValue: countryName
            })(
              <Input
                className="profile-tf-2 form-control"
                disabled={true} />
            )}
          </FormItem>
        </div>
        <div className="user-item-2">
          <FormItem className="user-custom-row" label="Your ID verification document">
            {getFieldDecorator('id_document_type')(
              <Dropdown
                trigger={['click']}
                overlay={this.renderIDParent()}
                disabled={!this.props.userReducer.user.data.attributes.is_editable}>
                <div className="dropdown-item">
                  <div>
                    {this.renderIDName()}
                  </div>
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>
          <FormItem className="user-custom-row" label="ID Document Number">
            {getFieldDecorator('id_document_number', {
              rules: [{ required: true, message: 'Please provide your ID document number' }, { validator: this.validateID }],
            })(
              <Input disabled={!this.props.userReducer.user.data.attributes.is_editable} className="profile-tf-2 form-control" />
            )}
          </FormItem>
        </div>
        <div className="user-item-2">
          <FormItem className="user-custom-row" label="Occupation">
            {getFieldDecorator('occupation', {
              initialValue: this.props.userReducer.user.data.attributes.occupation
            })(
              <Input
                className="profile-tf-2 form-control"
                disabled={!this.props.userReducer.user.data.attributes.is_editable} />
            )}
          </FormItem>
          <FormItem className="user-custom-row" label="Address Line 2">
            {getFieldDecorator('address_line_2', {
              rules: [{ required: true, message: 'Please provide your address line 2' }],
              initialValue: this.props.userReducer.user.data.attributes.address_line_2
            })(
              <Input className="profile-tf-2 form-control"
                disabled={!this.props.userReducer.user.data.attributes.is_editable} />
            )}
          </FormItem>
        </div>
        <div className="user-item-2">
          <FormItem className="user-custom-row" label="Postal Code">
            {getFieldDecorator('postal_code', {
              initialValue: this.props.userReducer.user.data.attributes.postcode === "0000" ? "" : this.props.userReducer.user.data.attributes.postcode
            })(
              <Input
                className="profile-tf-2 form-control"
                disabled={!this.props.userReducer.user.data.attributes.is_editable} />
            )}
          </FormItem>
          <FormItem className="su-custom-row" label="State/Province">
            {getFieldDecorator('state_province', {
              rules: [{ required: false }],
              initialValue: this.state.chosenProvince
            })(
              this.baseCountryIsSA()
              ? (
                  <Dropdown
                    className="user-tf-2"
                    trigger={['click']}
                    overlay={this.renderProvinceParent()}
                    disabled={!this.props.userReducer.user.data.attributes.is_editable}>
                    <div className="dropdown-item">
                      <div>
                        {this.renderProvinceName()}
                      </div>
                      <Icon type="down" />
                    </div>
                  </Dropdown>
                )
              : (
                  <Input
                    className="profile-tf-2 form-control"
                    disabled={true} />       
                )
            )}
          </FormItem>
        </div>
        <div className="user-item-2">
          <FormItem className="user-custom-row" label="Address Line 1">
            {getFieldDecorator('address_line_1', {
              rules: [{ required: true, message: 'Please provide your address line 1' }],
              initialValue: this.props.userReducer.user.data.attributes.address_line_1
            })(
              <Input className="profile-tf-2 form-control"
                disabled={!this.props.userReducer.user.data.attributes.is_editable} />
            )}
          </FormItem>
          <FormItem className="user-custom-row" label="Nationality">
            {getFieldDecorator('nationality', {
              rules: [{ required: true, message: 'Please provide your nationality' }],
            })(
              <Input
                className="profile-tf-2 form-control"
                disabled={true} />
            )}
          </FormItem>
        </div>
        <div className="user-item-2">
          <FormItem className="user-custom-row" label="City/Town">
            {getFieldDecorator('city_town', {
              rules: [{ required: true, message: 'Please provide your city or town' }],
              initialValue: this.props.userReducer.user.data.attributes.city
            })(
              <Input
                className="profile-tf-2 form-control"
                disabled={!this.props.userReducer.user.data.attributes.is_editable} />
            )}
          </FormItem>
        </div>



        <div className="user-item-2">
          <FormItem className="su-custom-row">
            {getFieldDecorator('send_promotions', {
              initialValue: this.props.userReducer.user.data.attributes.notify_promotions
            })(
              <Checkbox defaultChecked={this.props.userReducer.user.data.attributes.notify_promotions}>Send me promotions</Checkbox>
            )}
          </FormItem>
        </div>
        <div className="user-item-2">
          <FormItem className="user-custom-btn-row">
            <Button className="user-custom-btn"
              style={{
                backgroundColor: '#154989',
                boxSizing: 'border-box',
                color: 'white',
                marginBottom: '8px',
                width: '100%'
              }} type="primary" htmlType="submit" disabled={!this.props.userReducer.user.data.attributes.phone_verified}>UPDATE ACCOUNT</Button>
          </FormItem>
        </div>
      </Form>
    )
  }

  renderHWStatus(getFieldDecorator) {
    Log.d(TAG, "renderHWStatus() -- user=" + JSON.stringify(this.props.userReducer.user))
    if (Object.keys(this.props.userReducer.user).length > 0) {
      var nationality = this.props.userReducer.user.data.relationships.id_nationality.data.id
      Log.d(TAG, "renderHWStatus() -- use nationality ID=" + nationality)
      Log.d(TAG, "renderHWStatus() -- original home wallet=" + this.props.userReducer.user.data.attributes.mobile_wallet_auto_create)
      if (this.props.userReducer.user.data.attributes.mobile_wallet_auto_create !== null || this.props.userReducer.user.data.attributes.mobile_wallet_auto_create !== undefined) {
        if (nationality === "246" && this.props.userReducer.user.data.attributes.mobile_wallet_auto_create === false) {
          Log.d(TAG, "renderHWStatus() -- show not yet opted-in")
          return (<h4 style={{ textAlign: 'center', marginTop: '8px' }}>Home Wallet Status: Not yet opted-in</h4>)
        } else if (nationality === "246" && this.props.userReducer.user.data.attributes.mobile_wallet_auto_create === true) {
          return (<h4 style={{ textAlign: 'center', marginTop: '8px' }}>Home Wallet Status: Opted-in</h4>)
        }
      }
    }
    return (<span />)
  }

  renderHomeWallet(getFieldDecorator) {
    //add checking if the user has home wallet or not; check if user is from ZW
    Log.d(TAG, "renderHomeWallet() -- user=" + JSON.stringify(this.props.userReducer.user))
    if (Object.keys(this.props.userReducer.user).length > 0) {
      var nationality = this.props.userReducer.user.data.relationships.id_nationality.data.id
      Log.d(TAG, "renderHomeWallet() -- use nationality ID=" + nationality)
      Log.d(TAG, "renderHomeWallet() -- original home wallet=" + this.props.userReducer.user.data.attributes.mobile_wallet_auto_create)
      if (this.props.userReducer.user.data.attributes.mobile_wallet_auto_create !== null || this.props.userReducer.user.data.attributes.mobile_wallet_auto_create !== undefined) {
        if (nationality === "246" && this.props.userReducer.user.data.attributes.mobile_wallet_auto_create === false && this.props.userReducer.user.data.attributes.phone_verified) {
          return (
            <div className="user-item-2">
              <FormItem className="su-custom-row">
                {getFieldDecorator('home_wallet', {
                  initialValue: this.props.userReducer.user.data.attributes.mobile_wallet_auto_create
                })(
                  <Checkbox checked={this.state.homeWallet} onChange={(e) => {
                    this.setState({
                      homeWallet: e.target.checked
                    });
                  }}>Create Home Wallet</Checkbox>
                )}
              </FormItem>
            </div>
          )
        }
      }
    }
  }

  checkIDNumber(idNumber) {
    return Helper.isValidSAID(idNumber);
  }

  validateID = (rule, value, callback) => {
    Log.d(TAG, "validateID() -- called ..")
    if(this.baseCountryIsSA) {
      if (value && Number(this.state.chosenIDType) === 0) {
        if (this.checkIDNumber(value)) {
          callback();
        } else {
          callback('This field has invalid SA ID number.');
        }
      } else {
        callback();
      }
    } else {
      callback();
    }
  }

  renderProvinceParent() {
    return (
      <Menu onClick={this.onChangeProvince}>
        {this.renderProvinces()}
      </Menu>
    )
  }

  renderProvinces() {
    return this.state.provinces.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.value}</Menu.Item>
      );
    })
  }

  onChangeProvince = (event) => {
    Log.d(TAG, "onChangeProvince() -- event.key=" + event.key);
    this.setState({
      chosenProvince: event.key
    })
    this.props.form.setFieldsValue({
      state_province: event.key
    })
  }

  renderProvinceName() {
    return (
      <div>
        {this.state.provinces[this.state.chosenProvince].value}
      </div>
    )
  }

  renderGenderParent() {
    return (
      <Menu onClick={this.onChangeGender}>
        {this.renderGenders()}
      </Menu>
    )
  }

  renderGenders() {
    return this.state.genders.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr}</Menu.Item>
      );
    })
  }

  onChangeGender = (event) => {
    Log.d(TAG, "onChangeGender() -- event.key=" + event.key);
    this.setState({
      chosenGender: event.key
    })
    this.props.form.setFieldsValue({
      gender: event.key
    })
  }

  renderGenderName() {
    return (
      <div>
        {this.state.genders[this.state.chosenGender]}
      </div>
    )
  }

  onChangeBirthDate = (date, dateString) => {
    this.props.form.setFieldsValue({
      birthDate: date
    })
  }

  onChangeCountry = (event) => {
    this.setState({ country: event.key })
    this.props.form.setFieldsValue({
      country: event.key
    })
  }

  renderCountriesParent() {
    return (
      <Menu onClick={this.onChangeCountry}>
        {this.renderCountries()}
      </Menu>
    )
  }

  renderCountries() {
    return this.state.countries.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.name}</Menu.Item>
      );
    })
  }

  renderIDParent() {
    return (
      <Menu onClick={this.onChangeID}>
        {this.renderIDs()}
      </Menu>
    )
  }

  renderIDs() {
    return this.state.idType.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.name}</Menu.Item>
      );
    })
  }

  onChangeID = (event) => {
    Log.d(TAG, "onChangeID() -- event.key=" + event.key);
    this.setState({
      chosenIDType: event.key
    })
    this.props.form.setFieldsValue({
      id_document_type: event.key
    })

    if (this.props.form.getFieldValue('id_document_number') && Number(event.key) === 0) {
      if (this.checkIDNumber(this.props.form.getFieldValue('id_document_number'))) {
        this.setNoErrorID();
      } else {
        Helper.setError(this.props.form, "id_document_number", 'This field has invalid SA ID number.');
      }
    } else {
      this.setNoErrorID();
    }
  }

  renderIDName() {
    return (
      <div>
        {this.state.idType[this.state.chosenIDType].name}
      </div>
    )
  }
}

class DocumentManagement extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "DocumentManagement::constructor() -- props1=" + JSON.stringify(props));
    this.state = {
      addressDocument: null,
      document: null,
      addressDocumentName: '',
      documentName: '',
      idType: this.manageUrls.getBaseCountry() === baseUrlOptions.SOUTH_AFRICA ? this.getSAIDTypes() : this.getUKIDTypes(),
      addressDocuments: [
        {
          "type": "",
          "name": "Select your address document"
        },
        {
          "type": "UB",
          "name": "Utility Bill"
        },
        {
          "type": "LA",
          "name": "Lease or rental agreement"
        },
        {
          "type": "LB",
          "name": "Landlord’s letter PLUS utility bill in the landlord’s name"
        },
        {
          "type": "PA",
          "name": "Police affidavit"
        },
        {
          "type": "BS",
          "name": "Bank Statement"
        },
        {
          "type": "SS",
          "name": "Account statement by a retail store"
        },
        {
          "type": "CS",
          "name": "Telephone or cellular statement"
        },
        {
          "type": "VL",
          "name": "TV license or motor vehicle license"
        },
        {
          "type": "RT",
          "name": "Municipal Rates or Tax Statement"
        },
        {
          "type": "IP",
          "name": "Insurance policy"
        },
        {
          "type": "MS",
          "name": "Mortgage statement"
        },
        {
          "type": "OT",
          "name": "Other"
        }
      ],
      chosenIDType: 0,
      chosenAddressDocument: 0,
      idNumberDisabled: false
    }
  }
  

  manageUrls = new ManageUrls()
  uploadUrl = this.manageUrls.getUploadUrl()

  componentDidUpdate(prevProps) {
    if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
      if (this.props.userReducer.success === "ID has been uploaded successfully and currently pending for approval." ||
        this.props.userReducer.success === "Proof of residence has been uploaded successfully and currently pending for approval.")
        Log.d(TAG, "componentDidUpdate() -- reset documents")
      this.setState({
        addressDocument: null,
        document: null,
        addressDocumentName: '',
        documentName: '',
        chosenIDType: 0,
        chosenAddressDocument: 0,
      })
      this.checkUserInfo(0);
      // this.props.loadSelfieTab();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      Log.d(TAG, "handleSubmit() -- err=" + !err);
      if (!err) {
        // this.checkDocumentsUploaded(this.props.cookies.cookies.access, values);
        this.checkDocumentsUploaded(this.props.authReducer.accessToken, values);
      }
    });
  }

  checkIDNumber(idNumber) {
    return Helper.isValidSAID(idNumber);
  }

  checkBdayAndIDNumber() {

    if (this.props.userReducer.user.data.attributes.birth_date === null) {
      return false;
    }

    if (this.props.form.getFieldValue('id_document_number') === null || this.props.form.getFieldValue('id_document_number') === undefined) {
      return false;
    }

    if (this.props.form.getFieldValue('id_document_number').length !== 13) {
      return false
    }

    var birthYear = this.props.userReducer.user.data.attributes.birth_date.substring(2, 4);
    var birthMonth = this.props.userReducer.user.data.attributes.birth_date.substring(5, 7);
    var birthDay = this.props.userReducer.user.data.attributes.birth_date.substring(8, 10);

    var idYear = this.props.form.getFieldValue('id_document_number').substring(0, 2);
    var idMonth = this.props.form.getFieldValue('id_document_number').substring(2, 4);
    var idDay = this.props.form.getFieldValue('id_document_number').substring(4, 6);

    Log.d(TAG, "checkBdayAndIDNumber() -- birthYear=" + birthYear + " birthMonth=" + birthMonth + " birthDay=" + birthDay);
    Log.d(TAG, "checkBdayAndIDNumber() -- idYear=" + idYear + " idMonth=" + idMonth + " idDay=" + idDay);

    if (birthYear !== idYear || birthMonth !== idMonth || birthDay !== idDay) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- called ..");
    this.props.form.setFieldsValue({
      address_document_type: this.state.chosenAddressDocument
    })
    this.setDefaultIDDoc()
  }

  getSAIDTypes() {
    return [
     {
       "type": "SI",
       "name": "South African ID"
     },
     {
       "type": "FP",
       "name": "Foreign Passport"
     },
     {
       "type": "AP",
       "name": "Asylum Permit"
     },
     {
       "type": "FI",
       "name": "Foreign ID"
     }
   ]; 
  }

  getUKIDTypes() {
   return [
     {
       "type": "PP",
       "name": "Passport"
     },
     {
       "type": "DL",
       "name": "UK Driving licence"
     },
     {
       "type": "HI",
       "name": "UK Home office ID"
     },
     {
       "type": "NC",
       "name": "National ID Card"
     }           
   ];
  }

  setDefaultIDDoc() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      var chosenIDType = this.state.chosenIDType;
      if (chosenIDType !== null || chosenIDType !== undefined) {
        for (var idIndex = 0; idIndex < this.state.idType.length; idIndex++) {
          if (this.state.idType[idIndex].type === this.props.userReducer.user.data.attributes.id_verification_type) {
            chosenIDType = idIndex;
          }
        }
      }
      this.props.form.setFieldsValue({
        id_document_type: chosenIDType
      });
      this.setState({
        chosenIDType: chosenIDType
      });
      this.checkUserInfo(chosenIDType);
    }
  }

  checkUserInfo(chosenIDType) {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (chosenIDType !== null || chosenIDType !== undefined) {
        Log.d(TAG, "checkUserInfo() -- user ID type=" + this.props.userReducer.user.data.attributes.id_verification_type);
        Log.d(TAG, "checkUserInfo() -- user ID number=" + this.props.userReducer.user.data.attributes.id_document_number);
        Log.d(TAG, "checkUserInfo() -- chosen ID type=" + this.state.idType[chosenIDType].type);
        this.props.form.setFieldsValue({
          id_document_number: (this.state.idType[chosenIDType].type === this.props.userReducer.user.data.attributes.id_verification_type) ? this.props.userReducer.user.data.attributes.id_document_number : ""
        });
        this.setState({
          idNumberDisabled: (this.state.idType[chosenIDType].type === this.props.userReducer.user.data.attributes.id_verification_type)
        })
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div >
        <h4 style={{ textAlign: "center" }}>Proof of Identification</h4>
        <Form className="documents-box-form"
          onSubmit={this.handleSubmit.bind(this)}>
          <div className="su-item-upload">

            <FormItem label="* Your ID verification document">
              {getFieldDecorator('id_document_type')(
                <Dropdown
                  trigger={['click']}
                  overlay={this.renderIDParent()}>
                  <div className="dropdown-item">
                    <div>
                      {this.renderIDName()}
                    </div>
                    <Icon type="down" />
                  </div>
                </Dropdown>
              )}
            </FormItem>
            <FormItem label="* ID Document Number">
              {getFieldDecorator('id_document_number')(
                <Input className="profile-tf-2 form-control" disabled={this.state.idNumberDisabled} />
              )}
            </FormItem>
            {(this.state.idType[this.state.chosenIDType].type !== "FI" && this.state.idType[this.state.chosenIDType].type !== "SI") && (
              <FormItem label="* ID Document Expiry Date">
              {getFieldDecorator('id_document_expiration_date')(
                <DatePicker
                  allowClear={false}
                  format={'YYYY/MM/DD'}
                  className="su-tf-2"
                  disabledDate={this.disabledPastDate} />
              )}
            </FormItem>)}
            <FormItem
              label={(
                <span>
                  <span style={{ color: 'red' }}>* </span>Your ID document
                  <Tooltip title="Upload your South African ID, Foreign Passport or Asylum Permit">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              )}>
              {getFieldDecorator('document')(
                <Upload
                  name="avatar"
                  className="su-upload"
                  listType="picture-card"
                  showUploadList={false}
                  action={`${this.uploadUrl}files/`}
                  beforeUpload={this.beforeUploadDocument}
                  onChange={this.handleUploadDocument}>
                  <div>
                    <Icon type={'plus'} />
                    <div className="ant-upload-text">{this.state.documentName !== '' ? this.state.documentName : 'Upload your ID Document'}</div>
                  </div>
                </Upload>
              )}
            </FormItem>
            {/* <FormItem label="Your proof of residence">
            {getFieldDecorator('address_document_type', {
              rules: [{ required: false }, { validator: this.validateAddressDocument }],
            })(
              <Dropdown
                trigger={['click']}
                overlay={this.renderAddressDocumentParent()}>
                <div className="dropdown-item">
                  <div>
                    {this.renderAddressDocumentName()}
                  </div>
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem> */}
            {/* <FormItem
            label={(
              <span>
                Your address document
                <Tooltip title="Upload your proof of residence document">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            )}>
            {getFieldDecorator('address_document', {
              rules: [{ required: false }, { validator: this.validateAddressDocument }],
            })(
            <Upload
              name="avatar"
              className="su-upload"
              listType="picture-card"
              showUploadList={false}
              action={`${this.uploadUrl}files/`}
              beforeUpload={this.beforeUploadAddressDocument}
              onChange={this.handleUploadAddressDocument}>
                <div>
                  <Icon type={'plus'} />
                  <div className="ant-upload-text">{this.state.addressDocumentName !== '' ? this.state.addressDocumentName : 'Upload your address document'}</div>
                </div>
              </Upload>
            )}
          </FormItem> */}
          </div>
          <div className="user-item-2">
            <FormItem className="user-custom-btn-row">
              <Button className="user-custom-btn"
                style={{
                  backgroundColor: '#154989',
                  boxSizing: 'border-box',
                  color: 'white',
                  marginBottom: '8px',
                  width: '100%'
                }} type="primary" htmlType="submit">UPLOAD DOCUMENTS</Button>
            </FormItem>
          </div>
        </Form>
      </div>
    )
  }

  // validateAddressDocument = (rule, value, callback) => {
  //   Log.d(TAG, "validateAddressDocument() -- rule=" + JSON.stringify(rule) + " current value=" + JSON.stringify(value));
  //   const form = this.props.form;

  //   var addressDocument = (form.getFieldValue("address_document") !== undefined) ? form.getFieldValue("address_document") : this.state.addressDocument;
  //   var docType = form.getFieldValue("address_document_type") === undefined ? this.state.chosenAddressDocument : parseInt(form.getFieldValue("address_document_type"), 10);
  //   var noType = (addressDocument !== undefined && docType === 0) && (addressDocument !== null && docType === 0);
  //   var noDocument = docType !== 0 && (addressDocument === undefined || addressDocument === null);

  //   if (noDocument || noType) {
  //     Log.d(TAG, "validateAddressDocument() -- show error");
  //     callback("Please provide your address document and document type")
  //   } else {
  //     Log.d(TAG, "validateAddressDocument() -- show no error");
  //     form.setFields({ "address_document": { value: form.getFieldValue("address_document"), errors: null } });
  //     form.setFields({ "address_document_type": {value: form.getFieldValue("address_document_type"), errors: null} });
  //     callback();
  //   }
  // }

  disabledPastDate = (current) => {
    return current && current < moment().endOf('day');
  }

  renderIDParent() {
    return (
      <Menu onClick={this.onChangeID}>
        {this.renderIDs()}
      </Menu>
    )
  }

  renderIDs() {
    return this.state.idType.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.name}</Menu.Item>
      );
    })
  }

  onChangeID = (event) => {
    Log.d(TAG, "onChangeID() -- event.key=" + event.key);
    this.setState({
      chosenIDType: event.key
    })
    this.props.form.setFieldsValue({
      id_document_type: event.key
    })
    this.checkUserInfo(event.key);
  }

  renderIDName() {
    return (
      <div>
        {this.state.idType[this.state.chosenIDType].name}
      </div>
    )
  }

  renderAddressDocumentParent() {
    return (
      <Menu onClick={this.onChangeAddressDocuments}>
        {this.renderAddressDocuments()}
      </Menu>
    )
  }

  renderAddressDocuments() {
    return this.state.addressDocuments.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.name}</Menu.Item>
      );
    })
  }

  onChangeAddressDocuments = (event) => {
    Log.d(TAG, "onChangeAddressDocuments() -- event.key=" + event.key);
    this.setState({
      chosenAddressDocument: event.key
    })
    this.props.form.setFieldsValue({
      address_document_type: event.key
    })
    setTimeout(() => {
      this.props.form.validateFields(['address_document_type', 'address_document']);
    }, 700);
  }

  renderAddressDocumentName() {
    return (
      <div>
        {this.state.addressDocuments[this.state.chosenAddressDocument].name}
      </div>
    )
  }

  checkDocumentsUploaded(token, values) {
    Log.d(TAG, "checkDocumentsUploaded() -- addressDocument=" + this.state.addressDocument)
    var hasIDDoc = this.state.documentName !== '' && this.state.document;
    var hasAddressDoc = this.state.addressDocumentName !== '' && this.state.addressDocument;

    Log.d(TAG, "checkDocumentsUploaded() -- hasIDDoc=" + hasIDDoc + " hasAddressDoc=" + hasAddressDoc)

    // reset
    //this.props.uploadDocument("", new FormData(),  new FormData());

    // TODO: fix this area where is shows error on ID even address has only been uploaded
    if (hasIDDoc) {
      var noIDNumber = this.props.form.getFieldValue("id_document_number") === "" || this.props.form.getFieldValue("id_document_number") === undefined;
      var noIDDate = this.props.form.getFieldValue("id_document_expiration_date") === "" || this.props.form.getFieldValue("id_document_expiration_date") === undefined;

      if (noIDNumber) {
        Log.d(TAG, "checkDocumentsUploaded() -- set error ..")
        this.props.form.setFields({
          id_document_number: {
            errors: [new Error('Please provide the ID Document number')],
          },
        });
        //else if ((this.state.idType[this.state.chosenIDType].name.indexOf("Passport") !== -1) && noIDDate) {
      } else if (this.state.idType[this.state.chosenIDType].name !== "South African ID" && this.state.idType[this.state.chosenIDType].name !== "Foreign ID" && noIDDate) {
        Log.d(TAG, "checkDocumentsUploaded() -- is passport")
        this.props.form.setFields({
          id_document_expiration_date: {
            errors: [new Error('Please provide the ID Document Expiration date')],
          },
        });
      } else {
        if (values.id_document_number && this.state.idType[this.state.chosenIDType].name === "South African ID") {
          if (this.checkIDNumber(this.props.form.getFieldValue("id_document_number"))) {
            if (this.checkBdayAndIDNumber()) {
              this.uploadID(values);
            } else {
              Helper.setError(this.props.form, "id_document_number", 'Birthdate did not match with SA ID number value combination');
            }
          } else {
            Helper.setError(this.props.form, "id_document_number", 'This field has invalid SA ID number.');
          }
        } else {
          this.uploadID(values);
        }
      }
    }

    if (hasAddressDoc) {
      Log.d(TAG, "checkDocumentsUploaded() -- request address ..")
      var addressDocumentFormData = new FormData();
      addressDocumentFormData.append('file_type', this.state.addressDocuments[this.state.chosenAddressDocument].type)
      addressDocumentFormData.append('user_file', this.state.addressDocument)
      // this.props.requestUploadFile(`${this.uploadUrl}files/`, addressDocumentFormData, this.props.cookies.cookies.access, "Proof of residence has been uploaded successfully and currently pending for approval.")
      this.props.requestUploadFile(`${this.uploadUrl}files/`, addressDocumentFormData, this.props.authReducer.accessToken, "Proof of residence has been uploaded successfully and currently pending for approval.", "proof of residence")
    }
  }

  uploadID(values) {
    Log.d(TAG, "uploadID() -- request ID ..")
    this.props.form.setFields({ "id_document_number": { errors: null } });
    this.props.form.setFields({ "id_document_expiration_date": { errors: null } });
    var documentFormData = new FormData();
    documentFormData.append('file_type', this.state.idType[this.state.chosenIDType].type)
    documentFormData.append('user_file', this.state.document)
    if (values.id_document_number) {
      documentFormData.append('document_number', values.id_document_number)
    } else {
      documentFormData.append('document_number', "N/A")
    }
    //comment block below after CASS-917 is done
    /*else {
      documentFormData.append('document_expiry_date', moment().format("YYYY-MM-DD"));
    }*/
    if (values.id_document_expiration_date) {
      documentFormData.append('document_expiry_date', values.id_document_expiration_date.format("YYYY-MM-DD"))
    }
    // this.props.requestUploadFile(`${this.uploadUrl}files/`, documentFormData, this.props.cookies.cookies.access, "ID has been uploaded successfully and currently pending for approval.")
    this.props.requestUploadFile(`${this.uploadUrl}files/`, documentFormData, this.props.authReducer.accessToken, "ID has been uploaded successfully and currently pending for approval.", "proof of id")
  }

  handleFile(info, documentType) {
    var fileList = info.fileList;
    var fileListLength = Object.keys(fileList).length;
    Log.d(TAG, "handleFile() -- fileList=" + JSON.stringify(fileList) + " length=" + fileListLength);
    if (fileListLength > 0) {
      var currentFile = fileList[fileList.length - 1];
      Log.d(TAG, "handleFile() -- currentFile=" + JSON.stringify(currentFile));
      var name = currentFile.name;
      Log.d(TAG, "handleFile() -- original name=" + name);
      if (documentType === 'id') {
        Log.d(TAG, "handleFile() -- is document");
        this.setState({
          documentName: name
        })
      } else {
        Log.d(TAG, "handleFile() -- is addressDocument");
        this.setState({
          addressDocumentName: name
        })
      }
    }
  }

  handleUploadDocument = (info) => {
    Log.d(TAG, "handleUploadDocument() -- called ..");
    Log.d(TAG, "handleUploadDocument() -- info=" + JSON.stringify(info));
    this.handleFile(info, 'id');
  }

  handleUploadAddressDocument = (info) => {
    Log.d(TAG, "handleUploadAddressDocument() -- called ..");
    Log.d(TAG, "handleUploadAddressDocument() -- info=" + JSON.stringify(info));
    this.handleFile(info, 'addressDocument');
  }

  beforeUploadAddressDocument = (file) => {
    this.beforeUploadFile(file, 'addressDocument')
    return false;
  }

  beforeUploadDocument = (file) => {
    this.beforeUploadFile(file, 'id')
    return false;
  }

  beforeUploadFile(file, documentType) {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isPDF = file.type === 'application/pdf';

    Log.d(TAG, "beforeUploadFile() -- isJPG=" + isJPG + " isPNG=" + isPNG + " isPDF=" + isPDF);
    if (!isJPG && !isPNG && !isPDF) {
      message.error('File format should be JPEG, PNG or PDF', 5);
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('Document file must smaller than 10MB', 5);
    }

    var validFile = (isJPG || isPNG || isPDF) && isLt2M;
    Log.d(TAG, "beforeUploadFile() -- isLt2M=" + isLt2M);
    Log.d(TAG, "beforeUploadFile() -- validFile=" + validFile);
    if (validFile) {
      Log.d(TAG, "beforeUploadFile() -- file=" + JSON.stringify(file));
      if (documentType === 'id') {
        Log.d(TAG, "beforeUploadFile() -- is document");
        this.setState({
          document: file
        })
      } else {
        Log.d(TAG, "beforeUploadFile() -- is addressDocument");
        this.setState({
          addressDocument: file
        })
        this.props.form.setFieldsValue({
          address_document: file
        })
      }
    }
  }
}

// class AddressDocumentManagement extends React.Component {

//   constructor(props) {
//     super(props);
//     Log.d(TAG, "DocumentManagement::constructor() -- props1=" + JSON.stringify(props));

//     this.state = {
//       addressDocument: null,
//       document: null,
//       addressDocumentName: '',
//       documentName: '',
//       addressDocuments: [
//         {
//           "type": "",
//           "name": "Select your address document"
//         },
//         {
//           "type": "UB",
//           "name": "Utility Bill"
//         },
//         {
//           "type": "LA",
//           "name": "Lease or rental agreement"
//         },
//         {
//           "type": "LB",
//           "name": "Landlord’s letter PLUS utility bill in the landlord’s name"
//         },
//         {
//           "type": "PA",
//           "name": "Police affidavit"
//         },
//         {
//           "type": "BS",
//           "name": "Bank Statement"
//         },
//         {
//           "type": "SS",
//           "name": "Account statement by a retail store"
//         },
//         {
//           "type": "CS",
//           "name": "Telephone or cellular statement"
//         },
//         {
//           "type": "VL",
//           "name": "TV license or motor vehicle license"
//         },
//         {
//           "type": "RT",
//           "name": "Municipal Rates or Tax Statement"
//         },
//         {
//           "type": "IP",
//           "name": "Insurance policy"
//         },
//         {
//           "type": "MS",
//           "name": "Mortgage statement"
//         },
//         {
//           "type": "OT",
//           "name": "Other"
//         }
//       ],
//       chosenAddressDocument: 0,
//     }
//   }

//   manageUrls = new ManageUrls()
//   uploadUrl = this.manageUrls.getUploadUrl()

//   componentDidUpdate(prevProps) {
//     if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
//       if (this.props.userReducer.success === "Proof of residence has been uploaded successfully and currently pending for approval.")
//         Log.d(TAG, "componentDidUpdate() -- reset documents")
//       this.setState({
//         addressDocument: null,
//         document: null,
//         addressDocumentName: '',
//         documentName: '',
//         chosenAddressDocument: 0,
//       })
//       this.props.form.setFieldsValue({
//         address_document_type: undefined,
//         address_document: null,
//       });

//     }
//   }

//   handleSubmit(e) {
//     e.preventDefault();
//     this.props.form.validateFieldsAndScroll((err, values) => {
//       Log.d(TAG, "handleSubmit() -- err=" + !err);
//       if (!err) {
//         // this.checkDocumentsUploaded(this.props.cookies.cookies.access, values);
//         this.checkDocumentsUploaded(this.props.authReducer.accessToken, values);
//       }
//     });
//   }

//   componentDidMount() {
//     Log.d(TAG, "componentDidMount() -- called ..");
//     this.props.form.setFieldsValue({
//       address_document_type: this.state.chosenAddressDocument
//     })
//   }

//   render() {
//     const { getFieldDecorator } = this.props.form;

//     return (
//       <div >
//         <h4 style={{ textAlign: "center" }}>Proof of Address</h4>
//         <Form className="documents-box-form"
//           onSubmit={this.handleSubmit.bind(this)}>
//           <div className="su-item-upload">

//             <FormItem label="* Your proof of address">
//               {getFieldDecorator('address_document_type', {
//                 rules: [{ required: false }, { validator: this.validateAddressType }],
//               })(
//                 <Dropdown
//                   trigger={['click']}
//                   overlay={this.renderAddressDocumentParent()}>
//                   <div className="dropdown-item">
//                     <div>
//                       {this.renderAddressDocumentName()}
//                     </div>
//                     <Icon type="down" />
//                   </div>
//                 </Dropdown>
//               )}
//             </FormItem>
//             <FormItem
//               label={(
//                 <span>
//                   <span style={{ color: 'red' }}>* </span>Your address document
//                   <Tooltip title="Upload your proof of residence document">
//                     <Icon type="question-circle-o" />
//                   </Tooltip>
//                 </span>
//               )}>
//               {getFieldDecorator('address_document', {
//                 rules: [{ required: false }, { validator: this.validateAddressDocument }],
//               })(
//                 <Upload
//                   name="avatar"
//                   className="su-upload"
//                   listType="picture-card"
//                   showUploadList={false}
//                   action={`${this.uploadUrl}files/`}
//                   beforeUpload={this.beforeUploadAddressDocument}
//                   onChange={this.handleUploadAddressDocument}>
//                   <div>
//                     <Icon type={'plus'} />
//                     <div className="ant-upload-text">{this.state.addressDocumentName !== '' ? this.state.addressDocumentName : 'Upload your address document'}</div>
//                   </div>
//                 </Upload>
//               )}
//             </FormItem>
//           </div>
//           <div className="user-item-2">
//             <FormItem className="user-custom-btn-row">
//               <Button className="user-custom-btn"
//                 style={{
//                   backgroundColor: '#154989',
//                   boxSizing: 'border-box',
//                   color: 'white',
//                   marginBottom: '8px',
//                   width: '100%'
//                 }} type="primary" htmlType="submit">UPLOAD DOCUMENTS</Button>
//             </FormItem>
//           </div>
//         </Form>
//       </div>
//     )
//   }

//   validateAddressDocument = (rule, value, callback) => {
//     Log.d(TAG, "validateAddressDocument() -- rule=" + JSON.stringify(rule) + " current value=" + JSON.stringify(value));
//     const form = this.props.form;

//     var addressDocument = (form.getFieldValue("address_document") !== undefined) ? form.getFieldValue("address_document") : this.state.addressDocument;
//     var noDocument = addressDocument === undefined || addressDocument === null;

//     if (noDocument) {
//       Log.d(TAG, "validateAddressDocument() -- show error");
//       callback("Please provide your address document")
//     } else {
//       Log.d(TAG, "validateAddressDocument() -- show no error");
//       form.setFields({ "address_document": { value: form.getFieldValue("address_document"), errors: null } });
//       form.setFields({ "address_document_type": { value: form.getFieldValue("address_document_type"), errors: null } });
//       callback();
//     }
//   }

//   validateAddressType = (rule, value, callback) => {
//     Log.d(TAG, "validateAddressDocument() -- rule=" + JSON.stringify(rule) + " current value=" + JSON.stringify(value));
//     const form = this.props.form;

//     var docType = form.getFieldValue("address_document_type") === undefined ? this.state.chosenAddressDocument : parseInt(form.getFieldValue("address_document_type"), 10);
//     var noType = docType === 0;

//     if (noType) {
//       Log.d(TAG, "validateAddressDocument() -- show error");
//       callback("Please provide your address document type")
//     } else {
//       Log.d(TAG, "validateAddressDocument() -- show no error");
//       form.setFields({ "address_document": { value: form.getFieldValue("address_document"), errors: null } });
//       form.setFields({ "address_document_type": { value: form.getFieldValue("address_document_type"), errors: null } });
//       callback();
//     }
//   }

//   renderAddressDocumentParent() {
//     return (
//       <Menu onClick={this.onChangeAddressDocuments}>
//         {this.renderAddressDocuments()}
//       </Menu>
//     )
//   }

//   renderAddressDocuments() {
//     return this.state.addressDocuments.map((itemAr, index) => {
//       return (
//         <Menu.Item key={index}>{itemAr.name}</Menu.Item>
//       );
//     })
//   }

//   onChangeAddressDocuments = (event) => {
//     Log.d(TAG, "onChangeAddressDocuments() -- event.key=" + event.key);
//     this.setState({
//       chosenAddressDocument: event.key
//     })
//     this.props.form.setFieldsValue({
//       address_document_type: event.key
//     })
//     setTimeout(() => {
//       this.props.form.validateFields(['address_document_type', 'address_document']);
//     }, 700);
//   }

//   renderAddressDocumentName() {
//     return (
//       <div>
//         {this.state.addressDocuments[this.state.chosenAddressDocument].name}
//       </div>
//     )
//   }

//   checkDocumentsUploaded(token, values) {
//     Log.d(TAG, "checkDocumentsUploaded() -- addressDocument=" + this.state.addressDocument)
//     var hasAddressDoc = this.state.addressDocumentName !== '' && this.state.addressDocument;

//     Log.d(TAG, "checkDocumentsUploaded() -- hasAddressDoc=" + hasAddressDoc)

//     // reset
//     //this.props.uploadDocument("", new FormData(),  new FormData());

//     // TODO: fix this area where is shows error on ID even address has only been uploaded

//     if (hasAddressDoc) {
//       Log.d(TAG, "checkDocumentsUploaded() -- request address ..")
//       var addressDocumentFormData = new FormData();
//       addressDocumentFormData.append('file_type', this.state.addressDocuments[this.state.chosenAddressDocument].type)
//       addressDocumentFormData.append('user_file', this.state.addressDocument)
//       // this.props.requestUploadFile(`${this.uploadUrl}files/`, addressDocumentFormData, this.props.cookies.cookies.access, "Proof of residence has been uploaded successfully and currently pending for approval.")
//       this.props.requestUploadFile(`${this.uploadUrl}files/`, addressDocumentFormData, this.props.authReducer.accessToken, "Proof of residence has been uploaded successfully and currently pending for approval.")
//     }
//   }

//   handleFile(info, documentType) {
//     var fileList = info.fileList;
//     var fileListLength = Object.keys(fileList).length;
//     Log.d(TAG, "handleFile() -- fileList=" + JSON.stringify(fileList) + " length=" + fileListLength);
//     if (fileListLength > 0) {
//       var currentFile = fileList[fileList.length - 1];
//       Log.d(TAG, "handleFile() -- currentFile=" + JSON.stringify(currentFile));
//       var name = currentFile.name;
//       Log.d(TAG, "handleFile() -- original name=" + name);
//       if (documentType === 'id') {
//         Log.d(TAG, "handleFile() -- is document");
//         this.setState({
//           documentName: name
//         })
//       } else {
//         Log.d(TAG, "handleFile() -- is addressDocument");
//         this.setState({
//           addressDocumentName: name
//         })
//       }
//     }
//   }

//   handleUploadDocument = (info) => {
//     Log.d(TAG, "handleUploadDocument() -- called ..");
//     Log.d(TAG, "handleUploadDocument() -- info=" + JSON.stringify(info));
//     this.handleFile(info, 'id');
//   }

//   handleUploadAddressDocument = (info) => {
//     Log.d(TAG, "handleUploadAddressDocument() -- called ..");
//     Log.d(TAG, "handleUploadAddressDocument() -- info=" + JSON.stringify(info));
//     this.handleFile(info, 'addressDocument');
//   }

//   beforeUploadAddressDocument = (file) => {
//     this.beforeUploadFile(file, 'addressDocument')
//     return false;
//   }

//   beforeUploadDocument = (file) => {
//     this.beforeUploadFile(file, 'id')
//     return false;
//   }

//   beforeUploadFile(file, documentType) {
//     const isJPG = file.type === 'image/jpeg';
//     const isPNG = file.type === 'image/png';
//     const isPDF = file.type === 'application/pdf';

//     Log.d(TAG, "beforeUploadFile() -- isJPG=" + isJPG + " isPNG=" + isPNG + " isPDF=" + isPDF);
//     if (!isJPG && !isPNG && !isPDF) {
//       message.error('File format should be JPEG, PNG or PDF', 5);
//     }
//     const isLt2M = file.size / 1024 / 1024 < 10;
//     if (!isLt2M) {
//       message.error('Document file must smaller than 10MB', 5);
//     }

//     var validFile = (isJPG || isPNG || isPDF) && isLt2M;
//     Log.d(TAG, "beforeUploadFile() -- isLt2M=" + isLt2M);
//     Log.d(TAG, "beforeUploadFile() -- validFile=" + validFile);
//     if (validFile) {
//       Log.d(TAG, "beforeUploadFile() -- file=" + JSON.stringify(file));
//       if (documentType === 'id') {
//         Log.d(TAG, "beforeUploadFile() -- is document");
//         this.setState({
//           document: file
//         })
//       } else {
//         Log.d(TAG, "beforeUploadFile() -- is addressDocument");
//         this.setState({
//           addressDocument: file
//         })
//         this.props.form.setFieldsValue({
//           address_document: file
//         })
//       }
//     }
//   }
// }

// class IncomeDocumentManagement extends React.Component {

//   constructor(props) {
//     super(props);
//     Log.d(TAG, "DocumentManagement::constructor() -- props1=" + JSON.stringify(props));

//     this.state = {
//       incomeDocument: null,
//       document: null,
//       incomeDocumentName: '',
//       documentName: '',
//       incomeDocuments: [
//         {
//           "type": "",
//           "name": "Select your income document"
//         },
//         {
//           "type": "PS",
//           "name": "Payslip"
//         },
//         {
//           "type": "BS",
//           "name": "Bank Statement"
//         },
//         {
//           "type": "DA",
//           "name": "Income declaration affidavit"
//         },
//       ],
//       chosenIncomeDocument: 0,
//     }
//   }

//   manageUrls = new ManageUrls()
//   uploadUrl = this.manageUrls.getUploadUrl()

//   componentDidUpdate(prevProps) {
//     if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
//       if (this.props.userReducer.success === "Proof of income has been uploaded successfully and currently pending for approval.")
//         Log.d(TAG, "componentDidUpdate() -- reset documents")
//       this.setState({
//         incomeDocument: null,
//         document: null,
//         incomeDocumentName: '',
//         documentName: '',
//         chosenIncomeDocument: 1,
//       })
//       this.props.form.setFieldsValue({
//         income_document_type: undefined,
//         income_document: null,
//       });

//     }
//   }

//   handleSubmit(e) {
//     e.preventDefault();
//     this.props.form.validateFieldsAndScroll((err, values) => {
//       Log.d(TAG, "handleSubmit() -- err=" + !err);
//       if (!err) {
//         // this.checkDocumentsUploaded(this.props.cookies.cookies.access, values);
//         this.checkDocumentsUploaded(this.props.authReducer.accessToken, values);
//       }
//     });
//   }

//   componentDidMount() {
//     Log.d(TAG, "componentDidMount() -- called ..");
//     this.props.form.setFieldsValue({
//       income_document_type: this.state.chosenIncomeDocument
//     })
//   }

//   render() {
//     const { getFieldDecorator } = this.props.form;

//     return (
//       <div >
//         <h4 style={{ textAlign: "center" }}>Proof of Income</h4>
//         <Form className="documents-box-form"
//           onSubmit={this.handleSubmit.bind(this)}>
//           <div className="su-item-upload">

//             <FormItem label="* Your proof of income">
//               {getFieldDecorator('income_document_type', {
//                 rules: [{ required: false }, { validator: this.validateIncomeType }],
//               })(
//                 <Dropdown
//                   trigger={['click']}
//                   overlay={this.renderIncomeDocumentParent()}>
//                   <div className="dropdown-item">
//                     <div>
//                       {this.renderIncomeDocumentName()}
//                     </div>
//                     <Icon type="down" />
//                   </div>
//                 </Dropdown>
//               )}
//             </FormItem>
//             <FormItem
//               label={(
//                 <span>
//                   <span style={{ color: 'red' }}>* </span>Your income document
//                   <Tooltip title="Upload your proof of residence document">
//                     <Icon type="question-circle-o" />
//                   </Tooltip>
//                 </span>
//               )}>
//               {getFieldDecorator('income_document', {
//                 rules: [{ required: false }, { validator: this.validateIncomeDocument }],
//               })(
//                 <Upload
//                   name="avatar"
//                   className="su-upload"
//                   listType="picture-card"
//                   showUploadList={false}
//                   action={`${this.uploadUrl}files/`}
//                   beforeUpload={this.beforeUploadIncomeDocument}
//                   onChange={this.handleUploadIncomeDocument}>
//                   <div>
//                     <Icon type={'plus'} />
//                     <div className="ant-upload-text">{this.state.incomeDocumentName !== '' ? this.state.incomeDocumentName : 'Upload your income document'}</div>
//                   </div>
//                 </Upload>
//               )}
//             </FormItem>
//           </div>
//           <div className="user-item-2">
//             <FormItem className="user-custom-btn-row">
//               <Button className="user-custom-btn"
//                 style={{
//                   backgroundColor: '#154989',
//                   boxSizing: 'border-box',
//                   color: 'white',
//                   marginBottom: '8px',
//                   width: '100%'
//                 }} type="primary" htmlType="submit">UPLOAD DOCUMENTS</Button>
//             </FormItem>
//           </div>
//         </Form>
//       </div>
//     )
//   }

//   validateIncomeDocument = (rule, value, callback) => {
//     Log.d(TAG, "validateIncomeDocument() -- rule=" + JSON.stringify(rule) + " current value=" + JSON.stringify(value));
//     const form = this.props.form;

//     var incomeDocument = (form.getFieldValue("income_document") !== undefined) ? form.getFieldValue("income_document") : this.state.incomeDocument;
//     var noDocument = incomeDocument === undefined || incomeDocument === null;

//     if (noDocument) {
//       Log.d(TAG, "validateIncomeDocument() -- show error");
//       callback("Please provide your proof of income document")
//     } else {
//       Log.d(TAG, "validateIncomeDocument() -- show no error");
//       form.setFields({ "income_document": { value: form.getFieldValue("income_document"), errors: null } });
//       form.setFields({ "income_document_type": { value: form.getFieldValue("income_document_type"), errors: null } });
//       callback();
//     }
//   }

//   validateIncomeType = (rule, value, callback) => {
//     Log.d(TAG, "validateIncomeDocument() -- rule=" + JSON.stringify(rule) + " current value=" + JSON.stringify(value));
//     const form = this.props.form;

//     var docType = form.getFieldValue("income_document_type") === undefined ? this.state.chosenIncomeDocument : parseInt(form.getFieldValue("income_document_type"), 10);
//     var noType = docType === 0;

//     if (noType) {
//       Log.d(TAG, "validateIncomeDocument() -- show error");
//       callback("Please provide your income document type")
//     } else {
//       Log.d(TAG, "validateIncomeDocument() -- show no error");
//       form.setFields({ "income_document": { value: form.getFieldValue("income_document"), errors: null } });
//       form.setFields({ "income_document_type": { value: form.getFieldValue("income_document_type"), errors: null } });
//       callback();
//     }
//   }

//   renderIncomeDocumentParent() {
//     return (
//       <Menu onClick={this.onChangeIncomeDocuments}>
//         {this.renderIncomeDocuments()}
//       </Menu>
//     )
//   }

//   renderIncomeDocuments() {
//     return this.state.incomeDocuments.map((itemAr, index) => {
//       return (
//         <Menu.Item key={index}>{itemAr.name}</Menu.Item>
//       );
//     })
//   }

//   onChangeIncomeDocuments = (event) => {
//     Log.d(TAG, "onChangeIncomeDocuments() -- event.key=" + event.key);
//     this.setState({
//       chosenIncomeDocument: event.key
//     })
//     this.props.form.setFieldsValue({
//       income_document_type: event.key
//     })
//     setTimeout(() => {
//       this.props.form.validateFields(['income_document_type', 'income_document']);
//     }, 700);
//   }

//   renderIncomeDocumentName() {
//     return (
//       <div>
//         {this.state.incomeDocuments[this.state.chosenIncomeDocument].name}
//       </div>
//     )
//   }

//   checkDocumentsUploaded(token, values) {
//     Log.d(TAG, "checkDocumentsUploaded() -- incomeDocument=" + this.state.incomeDocument)
//     var hasIncomeDoc = this.state.incomeDocumentName !== '' && this.state.incomeDocument;

//     Log.d(TAG, "checkDocumentsUploaded() -- hasIncomeDoc=" + hasIncomeDoc)

//     // reset
//     //this.props.uploadDocument("", new FormData(),  new FormData());

//     // TODO: fix this area where is shows error on ID even income has only been uploaded

//     if (hasIncomeDoc) {
//       Log.d(TAG, "checkDocumentsUploaded() -- request income ..")
//       var incomeDocumentFormData = new FormData();
//       incomeDocumentFormData.append('file_type', this.state.incomeDocuments[this.state.chosenIncomeDocument].type)
//       incomeDocumentFormData.append('user_file', this.state.incomeDocument)
//       // this.props.requestUploadFile(`${this.uploadUrl}files/`, incomeDocumentFormData, this.props.cookies.cookies.access, "Proof of income has been uploaded successfully and currently pending for approval.")
//       this.props.requestUploadFile(`${this.uploadUrl}files/`, incomeDocumentFormData, this.props.authReducer.accessToken, "Proof of income has been uploaded successfully and currently pending for approval.")
//     }
//   }

//   handleFile(info, documentType) {
//     var fileList = info.fileList;
//     var fileListLength = Object.keys(fileList).length;
//     Log.d(TAG, "handleFile() -- fileList=" + JSON.stringify(fileList) + " length=" + fileListLength);
//     if (fileListLength > 0) {
//       var currentFile = fileList[fileList.length - 1];
//       Log.d(TAG, "handleFile() -- currentFile=" + JSON.stringify(currentFile));
//       var name = currentFile.name;
//       Log.d(TAG, "handleFile() -- original name=" + name);
//       if (documentType === 'id') {
//         Log.d(TAG, "handleFile() -- is document");
//         this.setState({
//           documentName: name
//         })
//       } else {
//         Log.d(TAG, "handleFile() -- is incomeDocument");
//         this.setState({
//           incomeDocumentName: name
//         })
//       }
//     }
//   }

//   handleUploadDocument = (info) => {
//     Log.d(TAG, "handleUploadDocument() -- called ..");
//     Log.d(TAG, "handleUploadDocument() -- info=" + JSON.stringify(info));
//     this.handleFile(info, 'id');
//   }

//   handleUploadIncomeDocument = (info) => {
//     Log.d(TAG, "handleUploadIncomeDocument() -- called ..");
//     Log.d(TAG, "handleUploadIncomeDocument() -- info=" + JSON.stringify(info));
//     this.handleFile(info, 'incomeDocument');
//   }

//   beforeUploadIncomeDocument = (file) => {
//     this.beforeUploadFile(file, 'incomeDocument')
//     return false;
//   }

//   beforeUploadDocument = (file) => {
//     this.beforeUploadFile(file, 'id')
//     return false;
//   }

//   beforeUploadFile(file, documentType) {
//     const isJPG = file.type === 'image/jpeg';
//     const isPNG = file.type === 'image/png';
//     const isPDF = file.type === 'application/pdf';

//     Log.d(TAG, "beforeUploadFile() -- isJPG=" + isJPG + " isPNG=" + isPNG + " isPDF=" + isPDF);
//     if (!isJPG && !isPNG && !isPDF) {
//       message.error('File format should be JPEG, PNG or PDF', 5);
//     }
//     const isLt2M = file.size / 1024 / 1024 < 10;
//     if (!isLt2M) {
//       message.error('Document file must smaller than 10MB', 5);
//     }

//     var validFile = (isJPG || isPNG || isPDF) && isLt2M;
//     Log.d(TAG, "beforeUploadFile() -- isLt2M=" + isLt2M);
//     Log.d(TAG, "beforeUploadFile() -- validFile=" + validFile);
//     if (validFile) {
//       Log.d(TAG, "beforeUploadFile() -- file=" + JSON.stringify(file));
//       if (documentType === 'id') {
//         Log.d(TAG, "beforeUploadFile() -- is document");
//         this.setState({
//           document: file
//         })
//       } else {
//         Log.d(TAG, "beforeUploadFile() -- is incomeDocument");
//         this.setState({
//           incomeDocument: file
//         })
//         this.props.form.setFieldsValue({
//           income_document: file
//         })
//       }
//     }
//   }
// }

// class WorkPermitDocumentManagement extends React.Component {

//   constructor(props) {
//     super(props);
//     Log.d(TAG, "DocumentManagement::constructor() -- props1=" + JSON.stringify(props));

//     this.state = {
//       workPermitDocument: null,
//       document: null,
//       workPermitDocumentName: '',
//       documentName: '',
//       workPermitDocuments: [
//         {
//           "type": "",
//           "name": "Select work permit document"
//         },
//         {
//           "type": "PM",
//           "name": "Work Permit"
//         },

//       ],
//       chosenWorkPermitDocument: 1,
//     }
//   }

//   manageUrls = new ManageUrls()
//   uploadUrl = this.manageUrls.getUploadUrl()

//   componentDidUpdate(prevProps) {
//     if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
//       if (this.props.userReducer.success === "Work Permit has been uploaded successfully and currently pending for approval.")
//         Log.d(TAG, "componentDidUpdate() -- reset documents")
//       this.setState({
//         workPermitDocument: null,
//         document: null,
//         workPermitDocumentName: '',
//         documentName: '',
//         chosenWorkPermitDocument: 1,
//       })
//       this.props.form.setFieldsValue({
//         work_permit_number: '',
//         work_permit_document_type: '',
//         work_permit_expiration_date: '',
//         work_permit_document: null,
//       });

//     }
//   }

//   handleSubmit(e) {
//     e.preventDefault();
//     this.props.form.validateFieldsAndScroll((err, values) => {
//       Log.d(TAG, "handleSubmit() -- err=" + !err);
//       if (!err) {
//         // this.checkDocumentsUploaded(this.props.cookies.cookies.access, values);
//         this.checkDocumentsUploaded(this.props.authReducer.accessToken, values);
//       }
//     });
//   }

//   componentDidMount() {
//     Log.d(TAG, "componentDidMount() -- called ..");
//     this.props.form.setFieldsValue({
//       work_permit_document_type: this.state.chosenWorkPermitDocument
//     })
//   }

//   disabledPastDate = (current) => {
//     return current && current < moment().endOf('day');
//   }

//   render() {
//     const { getFieldDecorator } = this.props.form;

//     return (
//       <div >
//         <h4 style={{ textAlign: "center" }}>Work Permit</h4>
//         <Form className="documents-box-form"
//           onSubmit={this.handleSubmit.bind(this)}>
//           <div className="su-item-upload">

//             <FormItem label="* Your work permit">
//               {getFieldDecorator('work_permit_document_type', {
//                 rules: [{ required: false }, { validator: this.validateWorkPermitType }],
//               })(
//                 <Dropdown
//                   trigger={['click']}
//                   overlay={this.renderWorkPermitDocumentParent()}>
//                   <div className="dropdown-item">
//                     <div>
//                       {this.renderWorkPermitDocumentName()}
//                     </div>
//                     <Icon type="down" />
//                   </div>
//                 </Dropdown>
//               )}
//             </FormItem>
//             <FormItem label="Work Permit Number">
//               {getFieldDecorator('work_permit_number', {
//                 rules: [{ required: true, message: "Please enter work permit number" }],
//               })(
//                 <Input className="profile-tf-2 form-control" disabled={this.state.workPermitDisabled} />
//               )}
//             </FormItem>
//             <FormItem label="Work Permit Expiry Date">
//               {getFieldDecorator('work_permit_expiration_date', {
//                 rules: [{ required: true, message: "Please enter work permit expiry date" }],
//               })(
//                 <DatePicker
//                   allowClear={false}
//                   format={'YYYY/MM/DD'}
//                   className="su-tf-2"
//                   disabledDate={this.disabledPastDate} />
//               )}
//             </FormItem>
//             <FormItem
//               label={(
//                 <span>
//                   <span style={{ color: 'red' }}>* </span>Your work permit document
//                   <Tooltip title="Upload your work permit document">
//                     <Icon type="question-circle-o" />
//                   </Tooltip>
//                 </span>
//               )}>
//               {getFieldDecorator('work_permit_document', {
//                 rules: [{ required: false }, { validator: this.validateWorkPermitDocument }],
//               })(
//                 <Upload
//                   name="avatar"
//                   className="su-upload"
//                   listType="picture-card"
//                   showUploadList={false}
//                   action={`${this.uploadUrl}files/`}
//                   beforeUpload={this.beforeUploadWorkPermitDocument}
//                   onChange={this.handleUploadWorkPermitDocument}>
//                   <div>
//                     <Icon type={'plus'} />
//                     <div className="ant-upload-text">{this.state.workPermitDocumentName !== '' ? this.state.workPermitDocumentName : 'Upload your work permit document'}</div>
//                   </div>
//                 </Upload>
//               )}
//             </FormItem>
//           </div>
//           <div className="user-item-2">
//             <FormItem className="user-custom-btn-row">
//               <Button className="user-custom-btn"
//                 style={{
//                   backgroundColor: '#154989',
//                   boxSizing: 'border-box',
//                   color: 'white',
//                   marginBottom: '8px',
//                   width: '100%'
//                 }} type="primary" htmlType="submit">UPLOAD DOCUMENTS</Button>
//             </FormItem>
//           </div>
//         </Form>
//       </div>
//     )
//   }

//   validateWorkPermitDocument = (rule, value, callback) => {
//     Log.d(TAG, "validateWorkPermitDocument() -- rule=" + JSON.stringify(rule) + " current value=" + JSON.stringify(value));
//     const form = this.props.form;

//     var workPermitDocument = (form.getFieldValue("work_permit_document") !== undefined) ? form.getFieldValue("work_permit_document") : this.state.workPermitDocument;
//     var noDocument = workPermitDocument === undefined || workPermitDocument === null;

//     if (noDocument) {
//       Log.d(TAG, "validateWorkPermitDocument() -- show error");
//       callback("Please upload work permit document")
//     } else {
//       Log.d(TAG, "validateWorkPermitDocument() -- show no error");
//       form.setFields({ "work_permit_document": { value: form.getFieldValue("work_permit_document"), errors: null } });
//       form.setFields({ "work_permit_document_type": { value: form.getFieldValue("work_permit_document_type"), errors: null } });
//       callback();
//     }
//   }

//   validateWorkPermitType = (rule, value, callback) => {
//     Log.d(TAG, "validateWorkPermitDocument() -- rule=" + JSON.stringify(rule) + " current value=" + JSON.stringify(value));
//     const form = this.props.form;

//     var docType = form.getFieldValue("work_permit_document_type") === undefined ? this.state.chosenWorkPermitDocument : parseInt(form.getFieldValue("work_permit_document_type"), 10);
//     var noType = docType === 0;

//     if (noType) {
//       Log.d(TAG, "validateWorkPermitDocument() -- show error");
//       callback("Please select work permit type")
//     } else {
//       Log.d(TAG, "validateWorkPermitDocument() -- show no error");
//       form.setFields({ "work_permit_document": { value: form.getFieldValue("work_permit_document"), errors: null } });
//       form.setFields({ "work_permit_document_type": { value: form.getFieldValue("work_permit_document_type"), errors: null } });
//       callback();
//     }
//   }

//   renderWorkPermitDocumentParent() {
//     return (
//       <Menu onClick={this.onChangeAddressDocuments}>
//         {this.renderAddressDocuments()}
//       </Menu>
//     )
//   }

//   renderAddressDocuments() {
//     return this.state.workPermitDocuments.map((itemAr, index) => {
//       return (
//         <Menu.Item key={index}>{itemAr.name}</Menu.Item>
//       );
//     })
//   }

//   onChangeAddressDocuments = (event) => {
//     Log.d(TAG, "onChangeAddressDocuments() -- event.key=" + event.key);
//     this.setState({
//       chosenWorkPermitDocument: event.key
//     })
//     this.props.form.setFieldsValue({
//       work_permit_document_type: event.key
//     })
//     setTimeout(() => {
//       this.props.form.validateFields(['work_permit_document_type', 'work_permit_document']);
//     }, 700);
//   }

//   renderWorkPermitDocumentName() {
//     return (
//       <div>
//         {this.state.workPermitDocuments[this.state.chosenWorkPermitDocument].name}
//       </div>
//     )
//   }

//   checkDocumentsUploaded(token, values) {
//     Log.d(TAG, "checkDocumentsUploaded() -- workPermitDocument=" + this.state.workPermitDocument)
//     var hasWorkPermitDoc = this.state.workPermitDocumentName !== '' && this.state.workPermitDocument;

//     Log.d(TAG, "checkDocumentsUploaded() -- hasWorkPermitDoc=" + hasWorkPermitDoc)

//     // reset
//     //this.props.uploadDocument("", new FormData(),  new FormData());

//     // TODO: fix this area where is shows error on ID even address has only been uploaded

//     if (hasWorkPermitDoc) {
//       Log.d(TAG, "checkDocumentsUploaded() -- request address ..")
//       var workPermitDocumentFormData = new FormData();
//       workPermitDocumentFormData.append('file_type', this.state.workPermitDocuments[this.state.chosenWorkPermitDocument].type)
//       workPermitDocumentFormData.append('user_file', this.state.workPermitDocument)
//       if (values.work_permit_number) {
//         workPermitDocumentFormData.append('document_number', values.work_permit_number)
//       } else {
//         workPermitDocumentFormData.append('document_number', "N/A")
//       }
//       //comment block below after CASS-917 is done
//       /*else {
//         documentFormData.append('document_expiry_date', moment().format("YYYY-MM-DD"));
//       }*/
//       if (values.work_permit_expiration_date) {
//         workPermitDocumentFormData.append('document_expiry_date', values.work_permit_expiration_date.format("YYYY-MM-DD"))
//       }
//       // this.props.requestUploadFile(`${this.uploadUrl}files/`, workPermitDocumentFormData, this.props.cookies.cookies.access, "Work Permit has been uploaded successfully and currently pending for approval.")
//       this.props.requestUploadFile(`${this.uploadUrl}files/`, workPermitDocumentFormData, this.props.authReducer.accessToken, "Work Permit has been uploaded successfully and currently pending for approval.")
//     }
//   }

//   handleFile(info, documentType) {
//     var fileList = info.fileList;
//     var fileListLength = Object.keys(fileList).length;
//     Log.d(TAG, "handleFile() -- fileList=" + JSON.stringify(fileList) + " length=" + fileListLength);
//     if (fileListLength > 0) {
//       var currentFile = fileList[fileList.length - 1];
//       Log.d(TAG, "handleFile() -- currentFile=" + JSON.stringify(currentFile));
//       var name = currentFile.name;
//       Log.d(TAG, "handleFile() -- original name=" + name);
//       if (documentType === 'id') {
//         Log.d(TAG, "handleFile() -- is document");
//         this.setState({
//           documentName: name
//         })
//       } else {
//         Log.d(TAG, "handleFile() -- is workPermitDocument");
//         this.setState({
//           workPermitDocumentName: name
//         })
//       }
//     }
//   }

//   handleUploadDocument = (info) => {
//     Log.d(TAG, "handleUploadDocument() -- called ..");
//     Log.d(TAG, "handleUploadDocument() -- info=" + JSON.stringify(info));
//     this.handleFile(info, 'id');
//   }

//   handleUploadWorkPermitDocument = (info) => {
//     Log.d(TAG, "handleUploadWorkPermitDocument() -- called ..");
//     Log.d(TAG, "handleUploadWorkPermitDocument() -- info=" + JSON.stringify(info));
//     this.handleFile(info, 'workPermitDocument');
//   }

//   beforeUploadWorkPermitDocument = (file) => {
//     this.beforeUploadFile(file, 'workPermitDocument')
//     return false;
//   }

//   beforeUploadDocument = (file) => {
//     this.beforeUploadFile(file, 'id')
//     return false;
//   }

//   beforeUploadFile(file, documentType) {
//     const isJPG = file.type === 'image/jpeg';
//     const isPNG = file.type === 'image/png';
//     const isPDF = file.type === 'application/pdf';

//     Log.d(TAG, "beforeUploadFile() -- isJPG=" + isJPG + " isPNG=" + isPNG + " isPDF=" + isPDF);
//     if (!isJPG && !isPNG && !isPDF) {
//       message.error('File format should be JPEG, PNG or PDF', 5);
//     }
//     const isLt2M = file.size / 1024 / 1024 < 10;
//     if (!isLt2M) {
//       message.error('Document file must smaller than 10MB', 5);
//     }

//     var validFile = (isJPG || isPNG || isPDF) && isLt2M;
//     Log.d(TAG, "beforeUploadFile() -- isLt2M=" + isLt2M);
//     Log.d(TAG, "beforeUploadFile() -- validFile=" + validFile);
//     if (validFile) {
//       Log.d(TAG, "beforeUploadFile() -- file=" + JSON.stringify(file));
//       if (documentType === 'id') {
//         Log.d(TAG, "beforeUploadFile() -- is document");
//         this.setState({
//           document: file
//         })
//       } else {
//         Log.d(TAG, "beforeUploadFile() -- is workPermitDocument");
//         this.setState({
//           workPermitDocument: file
//         })
//         this.props.form.setFieldsValue({
//           work_permit_document: file
//         })
//       }
//     }
//   }
// }

class SelfieForm extends React.Component {
  constructor(props) {
    super(props);
    Log.d(TAG, "DocumentManagement::constructor() -- props1=" + JSON.stringify(props));

    this.state = {
      document: null,
      documentName: ''
    }
  }

  manageUrls = new ManageUrls()
  uploadUrl = this.manageUrls.getUploadUrl()

  componentDidUpdate(prevProps) {
    if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
      if (this.props.userReducer.success === "Thanks for registering with Sasai Money Transfer. Your documents are being checked and you'll be notified once this has been completed.") {
        Log.d(TAG, "componentDidUpdate() -- reset selfie")
        this.setState({
          document: null,
          documentName: ''
        })
      }
    }
  }


  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      Log.d(TAG, "handleSubmit() -- err=" + !err);
      if (!err) {
        // this.checkDocumentsUploaded(this.props.cookies.cookies.access, values);
        this.checkDocumentsUploaded(this.props.authReducer.accessToken, values);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form className="user-body"
        onSubmit={this.handleSubmit.bind(this)}>
        <div className="su-item-upload">
          <div className="user-selfie-desc">
            <div style={{ display: "flex", flexDirection: "column"}}>
              <h3 className="user-title" id="selfie">Upload a selfie</h3>
              <img width={400} src={exampleSelfieImage} alt="example selfie image"/>
              <br />
              <b>Open the photo page of your passport/asylum/RSA ID. Take a photo of your face next to the photo page of your passport/asylum/RSA ID.</b>
            </div>
          </div>
          <FormItem
            label={(
              <span>
                Your selfie
                <Tooltip title="This will help us identify you">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            )}>
            {getFieldDecorator('selfie')(
              <Upload
                name="avatar"
                className="su-upload"
                listType="picture-card"
                showUploadList={false}
                action={`${this.uploadUrl}files/`}
                beforeUpload={this.beforeUploadDocument}
                onChange={this.handleUploadDocument}>
                <div>
                  <Icon type={'plus'} />
                  <div className="ant-upload-text">{this.state.documentName !== '' ? this.state.documentName : 'Upload your selfie'}</div>
                </div>
              </Upload>
            )}
          </FormItem>
          <div className="user-item-2">
            <FormItem className="user-custom-btn-row">
              <Button className="user-custom-btn"
                style={{
                  backgroundColor: '#154989',
                  boxSizing: 'border-box',
                  color: 'white'
                }} type="primary" htmlType="submit">UPLOAD SELFIE</Button>
            </FormItem>
          </div>
        </div>
      </Form>
    )
  }

  checkDocumentsUploaded(token, values) {
    var hasIDDoc = this.state.documentName !== '' && this.state.document;

    Log.d(TAG, "checkDocumentsUploaded() -- hasIDDoc=" + hasIDDoc)

    if (hasIDDoc) {
      Log.d(TAG, "checkDocumentsUploaded() -- request selfie upload ..")
      var documentFormData = new FormData();
      documentFormData.append('file_type', 'SL')
      documentFormData.append('user_file', this.state.document)
      documentFormData.append('document_number', "N/A")
      //documentFormData.append('document_expiry_date', moment().format("YYYY-MM-DD"));
      // this.props.requestUploadFile(`${this.uploadUrl}files/`, documentFormData, this.props.cookies.cookies.access, "Thanks for registering with Sasai Money Transfer. Your documents are being checked and you'll be notified once this has been completed.")
      this.props.requestUploadFile(`${this.uploadUrl}files/`, documentFormData, this.props.authReducer.accessToken, "Thanks for registering with Sasai Money Transfer. Your documents are being checked and you'll be notified once this has been completed.", "selfie")
    }
  }

  handleFile(info, documentType) {
    var fileList = info.fileList;
    var fileListLength = Object.keys(fileList).length;
    Log.d(TAG, "handleFile() -- fileList=" + JSON.stringify(fileList) + " length=" + fileListLength);
    if (fileListLength > 0) {
      var currentFile = fileList[fileList.length - 1];
      Log.d(TAG, "handleFile() -- currentFile=" + JSON.stringify(currentFile));
      var name = currentFile.name;
      Log.d(TAG, "handleFile() -- original name=" + name);
      if (documentType === 'id') {
        Log.d(TAG, "handleFile() -- is document");
        this.setState({
          documentName: name
        })
      }
    }
  }

  handleUploadDocument = (info) => {
    Log.d(TAG, "handleUploadDocument() -- called ..");
    Log.d(TAG, "handleUploadDocument() -- info=" + JSON.stringify(info));
    this.handleFile(info, 'id');
  }

  beforeUploadDocument = (file) => {
    this.beforeUploadFile(file, 'id')
    return false;
  }

  beforeUploadFile(file, documentType) {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isPDF = file.type === 'application/pdf';

    Log.d(TAG, "beforeUploadFile() -- isJPG=" + isJPG + " isPNG=" + isPNG + " isPDF=" + isPDF);
    if (!isJPG && !isPNG && !isPDF) {
      message.error('File format should be JPEG, PNG or PDF', 5);
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('Document file must smaller than 10MB', 5);
    }

    var validFile = (isJPG || isPNG || isPDF) && isLt2M;
    Log.d(TAG, "beforeUploadFile() -- isLt2M=" + isLt2M);
    Log.d(TAG, "beforeUploadFile() -- validFile=" + validFile);
    if (validFile) {
      Log.d(TAG, "beforeUploadFile() -- file=" + JSON.stringify(file));
      if (documentType === 'id') {
        Log.d(TAG, "beforeUploadFile() -- is document");
        this.setState({
          document: file
        })
        this.props.form.setFieldsValue({
          selfie: file
        })
      }
    }
  }
}

class EmailVerificationForm extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "EmailVerificationForm::constructor() -- props1=" + JSON.stringify(props));
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // if (this.props.cookies.cookies.access) {
        if (!!this.props.authReducer.accessToken) {
          // this.requestEmailConfirmation(this.props.cookies.cookies.access);
          this.requestEmailConfirmation(this.props.authReducer.accessToken);
        }
      }
    });
  }

  render() {
    return (
      <Form className="user-body"
        onSubmit={this.handleSubmit.bind(this)}>
        <h3 className="user-title" id="email-verification">Verify your email address</h3>
        <FormItem>
          <Button style={{ width: '100%' }} type="primary" htmlType="submit">Verify Email</Button>
        </FormItem>
      </Form>
    )
  }

  requestEmailConfirmation(access) {
    Log.d(TAG, "EmailVerificationForm::requestSubmitCode() -- called ..");
    var attributesObj = {};

    var dataObj = {};
    dataObj.type = "emails";
    dataObj.id = null;
    dataObj.attributes = attributesObj;

    var payloadObj = {};
    payloadObj.data = dataObj;

    Log.d(TAG, "EmailVerificationForm::requestEmailConfirmation() -- payload=" + JSON.stringify(payloadObj));
    this.props.requestEmailConfirmation(payloadObj, access);
  }
}

// class SelectSendingLimitsForm extends React.Component {

//   constructor(props) {
//     super(props);
//     Log.d(TAG, "SelectSendingLimitsForm::constructor() -- props1=" + JSON.stringify(props));

//     this.state = {
//       SendingLimitType: [
//         {
//           "type": "A1",
//           "name": "R5 000 daily/ R25 000 monthly"
//         },
//         {
//           "type": "A2",
//           "name": "R30 000 daily/ R70 000 monthly"
//         },
//         {
//           "type": "A3",
//           "name": "R60 000 daily/ R90 000 monthly"
//         },
//         {
//           "type": "A4",
//           "name": "R75 000 daily/ R90 000 monthly"
//         }
//       ],

//       chosenSendingLimitType: 0,
//       // chosenAddressDocument: 0,
//       // idNumberDisabled: false
//     }
//   }

//   handleSubmit(e) {
//     e.preventDefault();
//     this.props.form.validateFieldsAndScroll((err, values) => {
//       if (!err) {
//         // if (this.props.cookies.cookies.access) {
//         if (this.props.authReducer.accessToken) {
//           // const accessToken = this.props.cookies.cookies.access
//           const accessToken = this.props.authReducer.accessToken
//           const customerCode = this.props.userReducer.user.data.id
//           this.props.loadUploadDocsTab();
//           this.requestSubmitSendingLimitSelection(accessToken, customerCode);
//         }
//       }
//     });
//   }

//   sendingLimit1Details = (
//     <div>
//       <span style={{ fontWeight: 600 }}>Requirements:</span>
//       <ol type="1"><li>Proof of ID (one of the following)</li>
//         <ul>
//           <li>SA ID</li>
//           <li>Foreign Passport</li>
//           <li>Foreign ID</li>
//           <li>Asylum Seeker Permit</li>
//         </ul>
//       </ol>
//     </div>
//   )

//   sendingLimit2Details = (
//     <div>
//       <span style={{ fontWeight: 600 }}>Requirements:</span>
//       <ol type="1">
//         <li>Proof of ID (one of the following)</li>
//         <ul>
//           <li>SA ID</li>
//           <li>Foreign Passport</li>
//           <li>Asylum Seeker Permit</li>
//         </ul>
//         <li>Proof of Address</li>
//         <li>Proof of Income (one of the following)</li>
//         <ul>
//           <li>Payslip</li>
//           <li>Letter from employer and bank statement</li>
//           <li>Income declaration affidavit</li>
//         </ul>
//       </ol>
//     </div>
//   )

//   sendingLimit3Details = (
//     <div>
//       <span style={{ fontWeight: 600 }}>Requirements:</span>
//       <ol>
//         <li>Proof of ID (one of the following)</li>
//         <ul>
//           <li>SA ID</li>
//           <li>Foreign Passport</li>
//           <li>Asylum Seeker Permit</li>
//         </ul>
//         <li>Proof of Address</li>
//         <li>Proof of Income with a minimum salary of R50 000 (one of the following)</li>
//         <ul>
//           <li>Payslip</li>
//           <li>Letter from employer and bank statement</li>
//           <li>Income declaration affidavit</li>
//         </ul>
//       </ol>
//     </div>
//   )

//   sendingLimit4Details = (
//     <div>
//       <span style={{ fontWeight: 600 }}>Requirements:</span>
//       <ol>
//         <li>Proof of ID</li>
//         <ul>
//           <li>Foreign Passport</li>
//         </ul>
//         <li>Proof of Address</li>
//         <li>Proof of Income with a minimum salary of R50 000 (one of the following)</li>
//         <ul>
//           <li>Payslip</li>
//           <li>Letter from employer and bank statement</li>
//           <li>Income declaration affidavit</li>
//         </ul>
//         <li>Work Permit</li>
//       </ol>
//     </div>
//   )

//   getRequestedSendingLimit() {
//     let requestedSendingLimit = this.props.userReducer.user.data.attributes.product_change_request

//     switch (requestedSendingLimit) {
//       case 'A1': return '1';
//       case 'A2': return '2';
//       case 'A3': return '3';
//       case 'A4': return '4';
//       default: return '';
//     }
//   }

//   displayApprovedOrPending(sendingLimitAlphaCode) {
//     let approved = this.props.userReducer.user.data.attributes.user_sub_status
//     let pendingApproval = this.props.userReducer.user.data.attributes.product_change_request

//     const approvedNode = (<Tag color="#108ee9">Approved</Tag>)
//     const pendingApprovalNode = (<Tag>Pending Approval</Tag>)

//     switch (sendingLimitAlphaCode) {
//       case approved: return approvedNode;
//       case pendingApproval: return pendingApprovalNode;
//       default: return null;
//     }
//   }

//   onSubmitSendingLimit(e, selectedSendingLimt) {
//     e.preventDefault();

//     // if (this.props.cookies.cookies.access) {
//     if (this.props.authReducer.accessToken) {
//       // const accessToken = this.props.cookies.cookies.access
//       const accessToken = this.props.authReducer.accessToken
//       const customerCode = this.props.userReducer.user.data.id
//       this.props.loadUploadDocsTab();


//       const productObj = {}
//       productObj.product = selectedSendingLimt
//       const payloadObj = {
//         data: {
//           attributes: productObj,
//           id: null,
//           type: "product_change_requests"
//         }
//       }

//       Log.d(TAG, "SelectSendingLimitsForm::requestSubmitSendingLimitSelection() -- payload=" + JSON.stringify(payloadObj));
//       this.props.requestSubmitSendingLimitSelection(payloadObj, accessToken, customerCode);
      
//     }
//   }

//   disableSubmit(sendingLimit) {
//     let pendingApproval = this.props.userReducer.user.data.attributes.product_change_request
//     let approved = this.props.userReducer.user.data.attributes.user_sub_status

//     return sendingLimit === pendingApproval || sendingLimit === approved
//   }

//   render() {
//     const { Panel } = Collapse;

//     const buttonStyling = { height: 32, fontSize: 16 }

//     return (
//       <div style={{ display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
//         {/* <div style={{ width: "45%", minWidth: "300px" }}>
//           <Form className="user-body"
//             onSubmit={this.handleSubmit.bind(this)}>
//             <h3 className="user-title" id="">Select Sending Limit</h3>
//             <FormItem label="">
//               {getFieldDecorator('sending_limit')(
//                 <Dropdown
//                   trigger={['click']}
//                   overlay={this.renderSendingLimitParent()}>
//                   <div className="dropdown-item">
//                     <div>
//                       {this.renderSendingLimitName()}
//                     </div>
//                     <Icon type="down" />
//                   </div>
//                 </Dropdown>
//               )}
//             </FormItem>
//             <FormItem>
//               <Button style={{ width: '100%' }} type="primary" htmlType="submit">Submit</Button>
//             </FormItem>
//           </Form>
//         </div> */}
//         <div style={{ width: "45%", minWidth: "300px" }}>
//           {/* <h3 className="user-title" id="">Sending Limits Details</h3> */}
//           <h3 className="user-title" id="">Select Sending Limits</h3>
//           <Collapse accordion defaultActiveKey={[this.getRequestedSendingLimit()]} expandIconPosition='left'>
//             <Panel header="R5 000 daily/ R25 000 monthly" key="1" extra={this.displayApprovedOrPending('A1')}>
//               {this.sendingLimit1Details}
//               <Button type="primary" style={buttonStyling} onClick={e => this.onSubmitSendingLimit(e, 'A1')} disabled={this.disableSubmit('A1')}>Submit</Button>
//             </Panel>
//             {/* <Panel header="R30 000 daily/ R70 000 monthly" key="2" extra={this.displayApprovedOrPending('A2')}>
//               {this.sendingLimit2Details}
//               <Button type="primary" style={buttonStyling} onClick={e => this.onSubmitSendingLimit(e, 'A2')} disabled={this.disableSubmit('A2')}>Submit</Button>
//             </Panel>
//             <Panel header="R60 000 daily/ R90 000 monthly" key="3" extra={this.displayApprovedOrPending('A3')}>
//               {this.sendingLimit3Details}
//               <Button type="primary" style={buttonStyling} onClick={e => this.onSubmitSendingLimit(e, 'A3')} disabled={this.disableSubmit('A3')}>Submit</Button>
//             </Panel>
//             <Panel header="R75 000 daily/ R90 000 monthly" key="4" extra={this.displayApprovedOrPending('A4')}>
//               {this.sendingLimit4Details}
//               <Button type="primary" style={buttonStyling} onClick={e => this.onSubmitSendingLimit(e, 'A4')} disabled={this.disableSubmit('A4')}>Submit</Button>
//             </Panel> */}
//           </Collapse>
//         </div>
//       </div>
//     )
//   }

//   // customer code is the accounts id from the /accounts API 
//   // but also available on the /profile API
//   requestSubmitSendingLimitSelection(access, customerCode) {
//     Log.d(TAG, "SelectSendingLimitsForm::requestSubmitCode() -- called ..");

//     const productObj = {}
//     productObj.product = this.state.SendingLimitType[this.state.chosenSendingLimitType].type
//     const payloadObj = {
//       data: {
//         attributes: productObj,
//         id: null,
//         type: "product_change_requests"
//       }
//     }

//     Log.d(TAG, "SelectSendingLimitsForm::requestSubmitSendingLimitSelection() -- payload=" + JSON.stringify(payloadObj));
//     this.props.requestSubmitSendingLimitSelection(payloadObj, access, customerCode);
//   }

//   renderSendingLimitParent() {
//     return (
//       <Menu onClick={this.onChangeSendingLimit}>
//         {this.renderSendingLimits()}
//       </Menu>
//     )
//   }

//   renderSendingLimits() {
//     const approvedProduct = this.props.userReducer.user.data.attributes.user_sub_status
//     return this.state.SendingLimitType.map((itemAr, index) => {
//       return (
//         <Menu.Item disabled={approvedProduct === itemAr.type} key={index}>{itemAr.name}</Menu.Item>
//       );
//     })
//   }

//   onChangeSendingLimit = (event) => {
//     Log.d(TAG, "onChangeSendingLimit() -- event.key=" + event.key);
//     this.setState({
//       chosenSendingLimitType: event.key
//     })
//     this.props.form.setFieldsValue({
//       sending_limit: event.key
//     })
//     // this.checkUserInfo(event.key);
//   }

//   renderSendingLimitName() {
//     return (
//       <div>
//         {this.state.SendingLimitType[this.state.chosenSendingLimitType].name}
//       </div>
//     )
//   }
// }

class PhoneVerificationForm extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "PhoneVerificationForm::constructor() -- props1=" + JSON.stringify(props));
  }

  componentDidUpdate(prevProps) {
    if (this.props.userReducer.phoneConfirmationID !== null && prevProps.userReducer.phoneConfirmationID !== this.props.userReducer.phoneConfirmationID) {
      Log.d(TAG, "componentDidUpdate() -- set phoneConfirmationID=" + this.props.userReducer.phoneConfirmationID)
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // if (this.props.cookies.cookies.access) {
        if (!!this.props.authReducer.accessToken) {
          // this.requestSubmitCode(this.props.cookies.cookies.access, values.code);
          this.requestSubmitCode(this.props.authReducer.accessToken, values.code);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    var phonePrefix = getFieldDecorator('phonePrefix')(
      <div style={{ width: 45 }}>
        +{this.props.userReducer.user.data.attributes.mobile[0]}
      </div>
    );

    return (
      <Form className="user-body"
        onSubmit={this.handleSubmit.bind(this)}>
        <h3 className="user-title" id="phone-verification">Verify your mobile number</h3>
        <FormItem label="We need more information to verify your identity.">
          {getFieldDecorator('phone-verify', {
            rules: [{ required: true, message: 'Please provide your phone number' }],
            initialValue: this.props.userReducer.user.data.attributes.mobile[1]
          })(
            <Input addonBefore={phonePrefix} disabled={true} />
          )}
        </FormItem>
        <FormItem label="Sent code from SMS">
          {getFieldDecorator('code', {
            rules: [{
              required: true, message: 'Please provide the sent code.',
            }],
          })(
            <Input />
          )}
        </FormItem>
        <div className="user-phone-verification">
          <div className="user-phone-child">
            <FormItem>
              <Button className="user-phone-left-button" type="primary" htmlType="submit">Submit Code</Button>
            </FormItem>
            <FormItem>
              <Button type="primary" onClick={this.onConfirmPhone}>Resend SMS</Button>
            </FormItem>
          </div>
        </div>
      </Form>
    )
  }

  onConfirmPhone = () => {
    // if (this.props.cookies.cookies.access) {
    if (this.props.authReducer.accessToken) {
      // this.requestResendSMS(this.props.cookies.cookies.access)
      this.requestResendSMS(this.props.authReducer.accessToken)
    }
  }

  requestSubmitCode(access, code) {
    Log.d(TAG, "PhoneVerificationForm::requestSubmitCode() -- called ..");

    const id = require('uuid/v4')();
    Log.d(TAG, "PhoneVerificationForm::requestSubmitCode() -- id=" + id);
    var attributesObj = {};

    attributesObj.code = code;

    var dataObj = {};
    dataObj.type = "phones";
    dataObj.id = id;
    dataObj.attributes = attributesObj;

    var payloadObj = {};
    payloadObj.data = dataObj;

    Log.d(TAG, "PhoneVerificationForm::requestSubmitCode() -- payload=" + JSON.stringify(payloadObj));
    this.props.requestSubmitCode(this.props.userReducer.user.data.id, payloadObj, id, access);
  }

  requestResendSMS(access) {
    Log.d(TAG, "PhoneVerificationForm::requestResendSMS() -- called ..");

    var dataObj = {};
    dataObj.type = "phones";
    dataObj.id = null;
    dataObj.attributes = {};

    var payloadObj = {};
    payloadObj.data = dataObj;

    Log.d(TAG, "PhoneVerificationForm::requestResendSMS() -- payload=" + JSON.stringify(payloadObj));
    this.props.requestPhoneConfirmation(payloadObj, access);
  }
}

class FICALimit extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      limitOptions: ["Day", "Month", "Year"],
      chosenLimitOption: 0,
      note: "",
      cumulative: "0.00",
      remaining: "0.00",
      total: "0.00",
      cumulativeP: 0,
      remainingP: 0,
    }
  }

  componentDidMount() {
    // if (this.props.cookies.cookies.access) {
    if (this.props.authReducer.accessToken) {
      if (this.props.userReducer.user.data.attributes.user_sub_status === "AL" || this.props.userReducer.user.data.attributes.user_sub_status === "AF") {

      } else {
        this.setState({
          note: "If your FICA status is unavailable, please go to Profile to confirm your phone number and/or upload documents to validate your identity."
        })
      }
      // this.props.requestLimit(this.props.userReducer.user.data.id, this.props.cookies.cookies.access, "user-profile");
      this.props.requestLimit(this.props.userReducer.user.data.id, this.props.authReducer.accessToken, "user-profile");
    }
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(this.props.userReducer.limit).length > 0 && this.props.userReducer.limit !== prevProps.userReducer.limit) {
      var cpd = this.props.userReducer.limit.data.attributes.cumulative_per_day;
      var tpd = this.props.userReducer.limit.data.attributes.total_per_day;
      this.setState({
        cumulative: cpd,
        remaining: String(Number(tpd) - Number(cpd)),
        cumulativeP: (Number(cpd) / Number(tpd)) * 100,
        remainingP: ((Number(tpd) - Number(cpd)) / Number(tpd)) * 100
      })
    }
  }

  showSubStatus(subStatus) {
    // if (subStatus === "AL") {
    //   return "EcoCash Lite"
    // } else if (subStatus === "AF") {
    //   return "EcoCash Full"
    // } else {
    //   return "Not Available"
    // }

    switch (subStatus) {
      case "AL": return "EcoCash Lite";
      case "AF": return "EcoCash Full";
      case "A1": return "Product 1";
      case "A2": return "Product 2";
      case "A3": return "Product 3";
      case "A4": return "Product 4";
      default: return "Not Available";
    }
  }

  showLimit() {
    Log.d(TAG, "showLimit() -- limit=" + JSON.stringify(this.props.userReducer.limit))
    Log.d(TAG, "showLimit() -- chosenLimitOption=" + this.state.chosenLimitOption)
    if (Object.keys(this.props.userReducer.limit).length > 0) {

      var tpd = this.props.userReducer.limit.data.attributes.total_per_day;
      var tpm = this.props.userReducer.limit.data.attributes.total_per_month;
      var tpy = this.props.userReducer.limit.data.attributes.total_per_year;

      switch (this.state.chosenLimitOption) {
        case 0:
          Log.d(TAG, "showLimit() -- day limit")
          return tpd + " per day"
        case 1:
          Log.d(TAG, "showLimit() -- month limit")
          return tpm + " per month"
        case 2:
          Log.d(TAG, "showLimit() -- year limit")
          return tpy + " per year"
        default:
          Log.d(TAG, "showLimit() -- unavailable limit")
          return "Unavailable"
      }
    } else {
      return "Unavailable"
    }
  }

  renderLimitOptionsParent() {
    return (
      <Menu onClick={this.onChangeLimitOption}>
        {this.renderLimitOptions()}
      </Menu>
    )
  }

  renderLimitOptions() {
    return this.state.limitOptions.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr}</Menu.Item>
      );
    })
  }

  onChangeLimitOption = (event) => {
    Log.d(TAG, "onChangeLimitOption() -- event.key=" + event.key);
    var cpd = this.props.userReducer.limit.data.attributes.cumulative_per_day;
    var cpm = this.props.userReducer.limit.data.attributes.cumulative_per_month;
    var cpy = this.props.userReducer.limit.data.attributes.cumulative_per_year;

    var tpd = this.props.userReducer.limit.data.attributes.total_per_day;
    var tpm = this.props.userReducer.limit.data.attributes.total_per_month;
    var tpy = this.props.userReducer.limit.data.attributes.total_per_year;

    switch (Number(event.key)) {
      case 0:
        this.setState({
          cumulative: cpd,
          remaining: String(Number(tpd) - Number(cpd)),
          cumulativeP: (Number(cpd) / Number(tpd)) * 100,
          remainingP: ((Number(tpd) - Number(cpd)) / Number(tpd)) * 100
        })
        break;
      case 1:
        this.setState({
          cumulative: cpm,
          remaining: String(Number(tpm) - Number(cpm)),
          cumulativeP: (Number(cpm) / Number(tpm)) * 100,
          remainingP: ((Number(tpm) - Number(cpm)) / Number(tpm)) * 100
        })
        break;
      case 2:
        this.setState({
          cumulative: cpy,
          remaining: String(Number(tpy) - Number(cpy)),
          cumulativeP: (Number(cpy) / Number(tpy)) * 100,
          remainingP: ((Number(tpy) - Number(cpy)) / Number(tpy)) * 100
        })
        break;
      default:
        this.setState({
          cumulative: "0.00",
          remaining: "0.00",
          cumulativeP: 0,
          remainingP: 0
        })
        break;
    }

    this.setState({
      chosenLimitOption: Number(event.key)
    })
  }

  renderLimitOptionName() {
    return (
      <div>
        {this.state.limitOptions[this.state.chosenLimitOption]}
      </div>
    )
  }

  render() {
    return (
      <div className="fica-box">
        <h3 className="user-title" id="fica-limit">FICA limit</h3>
        <hr></hr>
        <div className="user-fica-parent">
          <b className="user-fica-text">Status: {this.showSubStatus(this.props.userReducer.user.data.attributes.user_sub_status)}</b>
          <Dropdown
            className="user-tf-2"
            trigger={['click']}
            overlay={this.renderLimitOptionsParent()}>
            <div className="dropdown-item">
              <div>
                {this.renderLimitOptionName()}
              </div>
              <Icon type="down" />
            </div>
          </Dropdown>
          <b className="user-fica-text">Current Limit: {this.showLimit()}</b>
          <Doughnut data={{
            labels: ["Usage: " + this.state.cumulativeP.toFixed(2) + "%", "Remaining: " + this.state.remainingP.toFixed(2) + "%"],
            datasets: [
              {
                data: [this.state.cumulativeP.toFixed(2), this.state.remainingP.toFixed(2)],
                backgroundColor: ["#57a6d0", "white"],
                borderColor: 'transparent'
              }
            ]
          }} />
        </div>
        <div className="user-fica-parent-details">
          <b className="user-fica-text">Cumulative ({this.state.limitOptions[this.state.chosenLimitOption]}): ZAR {Number(this.state.cumulative).toLocaleString()}</b>
          <b className="user-fica-text">Remaining ({this.state.limitOptions[this.state.chosenLimitOption]}): ZAR {Number(this.state.remaining).toLocaleString()}</b>
          <span className="user-fica-text">{this.state.note}</span>
        </div>
      </div>
    )
  }
}

class BreadWinnerPolicyForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      maxBirthDate: moment().subtract(18, 'years'),
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // if (this.props.cookies.cookies.access) {
        if (this.props.authReducer.accessToken) {

        }
      }
    });
  }

  componentDidUpdate(prevProps) {

  }

  validateBeneficiaryField = (rule, value, callback) => {
    const form = this.props.form;
    if (!value && form.getFieldValue('bread_winner')) {
      callback('This field is required');
    }
    callback();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        className="user-body"
        onSubmit={this.handleSubmit.bind(this)}>
        <div className="su-bread-winner-title-parent">
          <h3 className="user-title" id="bread-winner-policy">Breadwinner Policy</h3>
          <span>Breadwinners need to specify their beneficiary</span>
          <p className="su-bread-winner-note-title">No API has been implemented yet for breadwinner policy</p>
        </div>
        <div className="su-item-2">
          {this.renderBreadWinner(getFieldDecorator)}
        </div>
        <FormItem>
          {getFieldDecorator('bread_winner')(
            <Checkbox>I am a breadwinner</Checkbox>
          )}
        </FormItem>
      </Form>
    )
  }

  renderBreadWinner(getFieldDecorator) {
    var isBreadWinner = this.props.form.getFieldValue('bread_winner');

    if (isBreadWinner) {
      return (
        <div className="su-item-2">

          <FormItem className="su-custom-row" label="Beneficiary's first name">
            {getFieldDecorator('b_first_name', { rules: [{ validator: this.validateBeneficiaryField }] })(
              <Input
                className="su-tf-2" />
            )}
          </FormItem>
          <FormItem className="su-custom-row" label="Beneficiary's last name">
            {getFieldDecorator('b_last_name', { rules: [{ validator: this.validateBeneficiaryField }] })(
              <Input
                className="su-tf-2" />
            )}
          </FormItem>
          <FormItem className="su-custom-row" label="Beneficiary's ID number">
            {getFieldDecorator('b_id_number', { rules: [{ validator: this.validateBeneficiaryField }] })(
              <Input
                className="su-tf-2" />
            )}
          </FormItem>
          <FormItem className="su-custom-row"
            label={(
              <span>
                Beneficiary birth date
                <Tooltip title="Recipient must be 18 and not greater than 100 years old">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            )}>
            {getFieldDecorator('b_birth_date', { rules: [{ validator: this.validateBeneficiaryField }], initialValue: this.state.maxBirthDate })(
              <DatePicker
                allowClear={false}
                format={'YYYY/MM/DD'}
                className="su-tf-2"
                disabledDate={this.disabledDate} />
            )}
          </FormItem>
          <FormItem className="su-custom-row" label="Beneficiary's address">
            {getFieldDecorator('b_address', { rules: [{ validator: this.validateBeneficiaryField }] })(
              <Input
                className="su-tf-2" />
            )}
          </FormItem>
          <FormItem className="su-custom-row" label="Beneficiary's cell phone number">
            {getFieldDecorator('b_cellphone_number', { rules: [{ validator: this.validateBeneficiaryField }] })(
              <Input
                className="su-tf-2" />
            )}
          </FormItem>
          <FormItem className="su-custom-row" label="Beneficiary's email address">
            {getFieldDecorator('b_email_address', { rules: [{ validator: this.validateBeneficiaryField }, { type: 'email', message: 'Please provide a valid e-mail address' }] })(
              <Input
                className="su-tf-2" />
            )}
          </FormItem>
        </div>
      )
    } else {
      return <div />
    }
  }

  disabledDate = (current) => {
    Log.d(TAG, "disabledDate() -- current=" + current);
    if (current) {
      var hundredRangeMet = current < moment().subtract(100, 'years').endOf('day');
      var eighteenRangeMet = current > this.state.maxBirthDate.endOf('day');
      var rangeMet = hundredRangeMet || eighteenRangeMet
      return rangeMet;
    }
    return false;
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    fetchUserDetails,
    requestAccountUpdate,
    requestUploadFile,
    requestCardDetails,
    showCardList,
    deleteCard,
    requestSubmitCode,
    requestPhoneConfirmation,
    requestEmailConfirmation,
    requestLimit,
    requestCountries,
    requestSubmitSendingLimitSelection,
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return { ...state };
}

const WrappedBasicInformationForm = Form.create()(BasicInformationForm);
const WrappedPasswordForm = Form.create()(PasswordForm);
const WrappedExtendedInformationForm = Form.create()(ExtendedInformationForm);
const WrappedDocumentManagementForm = Form.create()(DocumentManagement);
// const WrappedAddressDocumentManagementForm = Form.create()(AddressDocumentManagement);
// const WrappedIncomeDocumentManagementForm = Form.create()(IncomeDocumentManagement);
// const WrappedWorkPermitDocumentManagementForm = Form.create()(WorkPermitDocumentManagement);
const WrappedSelfieForm = Form.create()(SelfieForm);
const WrappedPhoneVerificationForm = Form.create()(PhoneVerificationForm);
const WrappedEmailVerificationForm = Form.create()(EmailVerificationForm);
// const WrappedSelectSendingLimitsForm = Form.create()(SelectSendingLimitsForm);
const WrappedBreadWinnerPolicyForm = Form.create()(BreadWinnerPolicyForm);
export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserProfile)
