import React from 'react';
import { Layout, List, Card, Button } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import '../../styles/dashboard.css'
// import mailerImage from '../../images/mailer_pic.jpg'
// import rbzNotice from '../../images/EcoCash-RBZ-Notification2.jpg'
// import rbzNotice2 from '../../images/EcoCash Remit Notice_29_June_2020.jpg'
// import covid19Notice from '../../images/july-22-covid-19-notice.jpg'
// import ramadanPromotion from '../../images/website-blog-visual.jpg'
import thathaPromotion from '../../images/thatha-promo.jpg'
import lesothoCorridorLaunch from '../../images/lesotho-corridor-launch.jpg'
import zeroFeeFeesPress from '../../images/zero-fees-press.jpg'

const { Content, Header, Footer } = Layout;
const { Meta } = Card;

const data = [
  // {
  //   id: 1,
  //   title: 'Sasai Fintech launches cross border money transfer service from South Africa to the rest of Africa',
  //   author: 'Niel Joubert',
  //   date: '27 February 2019',
  //   location: 'JOHANNESBURG',
  //   slug: 'sasai-south-africa-launch',
  //   image: mailerImage
  // },
  // {
  //   id: 2,
  //   title: 'EcoCash RBZ Notice',
  //   author: 'Sasai Fintech',
  //   slug: 'ecocash-rbz-notice',
  //   image: rbzNotice
  // },
  // {
  //   id: 3,
  //   title: 'Ramadan Promotion',
  //   author: 'Sasai Fintech',
  //   slug: 'ramadan-promotion',
  //   image: ramadanPromotion
  // },
  // {
  //   id: 4,
  //   title: 'EcoCash RBZ Notice - 29 June 2020',
  //   author: 'Sasai Fintech',
  //   slug: 'ecocash-rbz-notice-2',
  //   image: rbzNotice2
  // },
  // {
  //   id: 5,
  //   title: 'Econet Shops Closure Notice - 22 July 2020',
  //   author: 'Sasai Fintech',
  //   slug: 'econet-shops-closure',
  //   image: covid19Notice 
  // },
  {
    id: 6,
    title: 'Thatha Amathousand Promotion',
    author: 'Sasai Fintech',
    slug: 'thatha-amathousand-promotion',
    image: thathaPromotion 
  },
  {
    id: 7,
    title: 'Sasai Transforms Cross-Border Money Transfer Convenience into Lesotho',
    author: 'Sasai Fintech',
    slug: 'sasai-lesotho-launch',
    image: lesothoCorridorLaunch 
  },
  {
    id: 8,
    title: 'Econet opens first free remittance corridors to Zimbabwe',
    slug: 'zero-fees-zim',
    author: 'Sasai Fintech',
    image: zeroFeeFeesPress 
  },
];

const reversedBlogs = data.reverse();

class Blog extends React.Component {

  componentDidMount() {
    document.title = "Blog"
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <div className="about-top-bg">
          <div className="misc-page-overlay">
            <h2 className="misc-top-title">BLOG</h2>
          </div>
        </div>
        <Content className="media-body">
        <List
          grid={{
            gutter: 20,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 6,
            xxl: 3,
          }}
          dataSource={reversedBlogs}
          renderItem={item => (
            <List.Item>
              <Card
                cover={<img alt="example" style={{ height: 240, objectFit: 'cover', objectPosition: '0 0'}} src={item.image} />}
                actions={[<Button onClick={() => {
                  this.props.history.push({
                    pathname: "/blog/" + item.id + "/" + item.slug,
                  });
                }}>Read More</Button>]}>
                <Meta title={item.title} description={item.author} />
              </Card>
            </List.Item>
          )}
          />
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

export default withCookies(Blog)
