import Log from "../utils/log.js";
import xhr from "../utils/xhr.js";
import Helper from "../utils/helper.js";
import Cookies from "universal-cookie";
import ManageUrls from "../endpoints/manage_urls";
import config from "../env-config.json";

const cookieHelper = new Cookies();
const TAG = "ServerData";
const manageUrls = new ManageUrls()

// const REFRESH_TOKEN_EXPIRATION = 60 * 60 * 24; // 1 day
// const ACCESS_TOKEN_EXPIRATION = 60 * 19.5; // 19 mins
// const ACCESS_TOKEN_EXPIRATION = 60; // 19 mins

class ServerData {
  // access token has expiry of 20 mins
  // while refresh token has expiry of 1 day

  static requestToken(cookies, url, payload, saveToken, recaptchaResponse, cb) {
    Log.d(TAG, "requestToken() -- url=" + url);
    var headers = Helper.getRefreshTokenHeaders();
    if (recaptchaResponse) {
      headers = Helper.getLoginHeaders(recaptchaResponse);
    }
    var isLogOutUrl = url.split('/')[url.split('/').length - 2] === 'logout';
    // check if the request is to log out the user 
    if (isLogOutUrl) {
      // payload contains access token that is needed by 
      // the Authorization header
      headers = Helper.getAuthHeaders(payload);
      // set payload to null so that the request doesn't a
      // body; there is conditional in xhr.postWithCredentials
      payload = null;
    }
    // xhr.post(url, payload, headers, (error, data) => {
    xhr.postWithCredentials(url, payload, headers, (error, data) => {
      Log.i(JSON.stringify({ error, data, payload }));
      if (error) {
        Log.d(TAG, "requestRefeshToken() -- error=" + error);
        cb(error);
      } else {
        Log.d(TAG, "requestRefeshToken() -- data=" + JSON.stringify(data));
        // if (data.hasOwnProperty("access") && data.hasOwnProperty("refresh")) {
        if (data.hasOwnProperty("access")) {
          Log.d(
            TAG,
            "requestRefeshToken() -- 1 access=" +
            data.access +
            " refresh token=" +
            data.refresh
          );
          if (isLogOutUrl !== 'logout') {
            ServerData.requestUserDetails(
              cookies,
              data.access,
              false,
              (error, userData) => {
                if (error) {
                  cb(error);
                } else {
                  Log.d(
                    TAG,
                    "requestRefeshToken() -- 2 access=" +
                    data.access +
                    " refresh token=" +
                    data.refresh
                  );
                  cb(null, data.access, data.refresh, userData);
                }
              }
            );
          }
        } else {
          cb(ServerData.joinErrorMessages(data, false));
        }
      }
    });
  }

  //payload: {"refresh": "saved refresh token"}
  static requestRefreshToken(cookies, payload, saveToken, cb) {
    ServerData.checkConfig(manageUrls.getAuthUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getAuthUrl() + "web/refresh/";
        Log.d(TAG, "requestRefeshToken() -- url=" + url);
        ServerData.requestToken(cookies, url, payload, saveToken, null, cb);
      }
    });
  }

  static logOut(cookies, payload, saveToken, cb) {
    ServerData.checkConfig(manageUrls.getAuthUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getAuthUrl() + "web/logout/";
        Log.d(TAG, "logOut() -- url=" + url);
        ServerData.requestToken(cookies, url, payload, saveToken, null, cb);
        cookies.remove("first_name");
        cookies.remove("last_name");
      }
    });
  }

  static requestLogin(cookies, payload, saveToken, recaptchaResponse, cb) {
    ServerData.checkConfig(manageUrls.getAuthUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getAuthUrl() + "web/login/";
        Log.d(
          TAG,
          "requestLogin() -- url=" + url + " payload=" + JSON.stringify(payload)
        );
        ServerData.requestToken(
          cookies,
          url,
          payload,
          saveToken,
          recaptchaResponse,
          cb
        );
      }
    });
  }

  static requestSignUp(payload, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "accounts/";
        Log.d(TAG, "requestSignUp() -- url=" + url);
        xhr.post(url, payload, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestSignUp() -- error=" + error);
            cb(error);
          } else {
            Log.d(TAG, "requestSignUp() -- data=" + JSON.stringify(data));
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestUserDetails(cookies, access, includeBW, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        var url = manageUrls.getApiUrl() + "profile/?include=country";
        if (includeBW) {
          url = manageUrls.getApiUrl() + "profile/?include=country,breadwinner_policy";
        }
        Log.d(TAG, "requestUserDetails() -- url=" + url);
        xhr.get(url, Helper.getHeaders(access), (error, data) => {
          if (error) {
            Log.d(TAG, "requestUserDetails() -- error=" + error);
            cb(error);
          } else {
            Log.d(TAG, "requestUserDetails() -- data=" + JSON.stringify(data));
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              if (
                data.data.attributes.user_type === 2 ||
                data.data.attributes.user_type === 25
              ) {
                Log.d(
                  TAG,
                  "requestUserDetails() -- first name=" +
                  data.data.attributes.first_name
                );
                Log.d(
                  TAG,
                  "requestUserDetails() -- last name=" +
                  data.data.attributes.last_name
                );
                // cookies.remove("first_name");
                // cookies.remove("last_name");
                // cookies.set("first_name", data.data.attributes.first_name, {
                //   maxAge: ACCESS_TOKEN_EXPIRATION
                // });
                // cookies.set("last_name", data.data.attributes.last_name, {
                //   maxAge: ACCESS_TOKEN_EXPIRATION
                // });
                cb(null, data);
              } else {
                // cookies.remove("access");
                // cookies.remove("refresh");
                // cookies.remove("first_name");
                // cookies.remove("last_name");
                cb(
                  "Unauthorized login: Only customers can login. Please login with your customer account credentials."
                );
              }
            }
          }
        });
      }
    });
  }

  static requestCountryDetails(countryID, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "countries/" + countryID + "/";
        Log.d(TAG, "requestCountryDetails() -- url=" + url);
        xhr.get(url, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestCountryDetails() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  // static requestSendingCountries(cb) {
  //   ServerData.checkConfig(manageUrls.getApiUrl(), error => {
  //     if (error) {
  //       cb(error);
  //     } else {
  //       let url = manageUrls.getApiUrl() + "sending_countries/";
  //       Log.d(TAG, "requestSendingCountries() -- url=" + url);
  //       xhr.get(url, Helper.getNoTokenHeaders(), (error, data) => {
  //         if (error) {
  //           Log.d(TAG, "requestSendingCountries() -- error=" + error);
  //           cb(error);
  //         } else {
  //           if (data.hasOwnProperty("errors")) {
  //             cb(ServerData.joinErrorMessages(data, true));
  //           } else {
  //             cb(null, data);
  //           }
  //         }
  //       });
  //     }
  //   });
  // }

  static requestSendingCountries(cb) {
    ServerData.checkConfig(manageUrls.getSAUrlValues(), error => {
      if (error) {
        cb(error);
      } else {

        // UK URL
        const ukValues = manageUrls.getUKValues()
        const ukUrl = ukValues.apiUrl + "sending_countries/";

        // SA URL
        const saValues = manageUrls.getSAUrlValues()
        const saUrl = saValues.apiUrl + "sending_countries/";

        // finalCallback responsible for merging response
        // data from the two requests into a single array 
        // of countries
        const finalCallback = (firstResponse) => {

          return (error, secondResponse) => {

            if (error) {
              Log.d(TAG, "requestSendingCountries() -- error=" + error);
              // cb(error);
            } else {
              Log.d(TAG, "requestSendingCountries() -- url=" + ukUrl);
              if (secondResponse.hasOwnProperty("errors")) {
                cb(ServerData.joinErrorMessages(secondResponse, true));
              } else {

                const mergedResponse = { data: [...firstResponse.data, ...secondResponse.data] }

                cb(null, mergedResponse);
              }
            }
          }
        }

        // second request to UK endpoint
        const secondRequest = (error, data) => {
          if (error) {
            Log.d(TAG, "requestSendingCountries() -- error=" + error);
            // cb(error);
          } else {
            Log.d(TAG, "requestSendingCountries() -- url=" + saUrl);
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              xhr.get(ukUrl, Helper.getNoTokenHeaders(), finalCallback(data));
            }
          }
        }

        // first request to SA endpoint
        xhr.get(saUrl, Helper.getNoTokenHeaders(), secondRequest);

      }
    });
  }

  static requestCountries(cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "countries/";
        Log.d(TAG, "requestCountries() -- url=" + url);
        xhr.get(url, Helper.getNoTokenHeaders(), (error, response) => {
          if (error) {
            Log.d(TAG, "requestCountries() -- error=" + error);
            cb(error);
          } else {
            if (response.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(response, true));
            } else {
              Log.d(
                TAG,
                "requestCountries() -- response=" + JSON.stringify(response)
              );
              cb(null, response.data);
            }
          }
        });
      }
    });
  }

  static requestReceivingCountries(sendingCountry, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getApiUrl() + "receiving_countries/" + sendingCountry + "/";
        Log.d(TAG, "requestReceivingCountries() -- url=" + url);
        xhr.get(url, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestReceivingCountries() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestAirtimeDataSendingCountries(cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getApiUrl() + "sending_countries/?filter[service_type]=AD";
        Log.d(TAG, "requestSendingCountries() -- url=" + url);
        xhr.get(url, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestSendingCountries() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestAirtimeDataReceivingCountries(sendingCountry, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getApiUrl() + "receiving_countries/" + sendingCountry + "/?filter[service_type]=AD";
        Log.d(TAG, "requestReceivingCountries() -- url=" + url);
        xhr.get(url, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestReceivingCountries() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestOTPCode(payload, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getAuthUrl() + "otp/generate/";
        Log.d(TAG, "requestOTPCode() -- url=" + url);
        xhr.postWithCustomError(url, payload, Helper.getAuthHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestOTPCode() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestOTPCodeClientSignUp(payload, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getAuthUrl() + "otp/generate/client/signup/";
        Log.d(TAG, "requestOTPCodeClientSignUp() -- url=" + url);
        xhr.postWithSignUpOTPError(url, payload, Helper.getAuthHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestOTPCodeClientSignUp() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestOTPSignIn(payload, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getAuthUrl() + "web/login/otp/signup/";
        Log.d(TAG, "requestOTPSignIn() -- url=" + url);
        xhr.postWithSignUpOTPError(url, payload, Helper.getAuthHeaders(), (error, data, goToNames, goToReg) => {
          if(goToNames) {
            cb(null, null, true)
          } else if(goToReg) {
            cb(null, null, false, true)
          } else if (error) {
            Log.d(TAG, "requestOTPSignIn() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestOTPRequestForLogin(payload, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getAuthUrl() + "otp/generate/client/";
        Log.d(TAG, "requestOTPRequestForLogin() -- url=" + url);
        xhr.postWithCustomError(url, payload, Helper.getAuthHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestOTPRequestForLogin() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestLoginUsingOTP(payload, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getAuthUrl() + "web/login/otp/";
        Log.d(TAG, "requestLoginUsingOTP() -- url=" + url);
        xhr.postWithCustomErrorOTP(url, payload, Helper.getAuthHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestLoginUsingOTP() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestGuestUserTokens(payload, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getAuthUrl() + "web/login/";
        Log.d(TAG, "requestGuestUserTokens() -- url=" + url);
        xhr.postWithCustomErrorOTP(url, payload, Helper.getAuthHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestGuestUserTokens() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestRecipients(url, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        Log.d(TAG, "requestRecipients() -- url=" + url + ", access=" + access);
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  static requestBWBeneficiaries(userID, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        var url =
          manageUrls.getApiUrl() + "accounts/" + userID + "/breadwinner_beneficiaries/";
        Log.d(
          TAG,
          "requestBWBeneficiaries() -- url=" + url + ", access=" + access
        );
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  static requestTransactions(url, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        Log.d(
          TAG,
          "requestTransactions() -- url=" + url + ", access=" + access
        );
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  static requestForgetPassword(payload, isEmail, id, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        var url = manageUrls.getApiUrl() + "email/passwords/";

        Log.d(TAG, "requestForgetPassword() -- isemail=" + isEmail);
        Log.d(TAG, "requestForgetPassword() -- id=" + id);
        var method = "POST";
        if (!isEmail) {
          Log.d(TAG, "requestForgetPassword() -- sms ");
          url = manageUrls.getApiUrl() + "sms/passwords/";
        }

        if (!isEmail && id) {
          Log.d(TAG, "requestForgetPassword() -- sms with id ");
          url = manageUrls.getApiUrl() + "sms/passwords/" + id + "/";
        }

        if (id !== "") {
          Log.d(TAG, "requestForgetPassword() -- update ");
          method = "PUT";
        }

        Log.d(TAG, "requestForgetPassword() -- url=" + url);
        xhr.requestWithBody(
          method,
          url,
          payload,
          Helper.getNoTokenHeaders(),
          (error, data) => {
            if (error) {
              Log.d(TAG, "requestForgetPassword() -- error=" + error);
              cb(error);
            } else {
              Log.d(
                TAG,
                "requestForgetPassword() -- data=" + JSON.stringify(data)
              );
              if (data.hasOwnProperty("errors")) {
                cb(ServerData.joinErrorMessages(data, true));
              } else {
                cb(null, data);
              }
            }
          }
        );
      }
    });
  }

  static requestBranchesByBankID(bankId, stateId, districtId, cityId, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "banks/" + bankId + "/branches/";
        if (stateId !== undefined) {
          url = manageUrls.getApiUrl() + "banks/" + bankId + "/branches?filter[state]=" + stateId + "&filter[district]=" + districtId + "&filter[city]=" + cityId
        } else if (districtId !== undefined) {
          url = manageUrls.getApiUrl() + "banks/" + bankId + "/branches?filter[district]=" + districtId
        }
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  static requestCpps(cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "cash_pickup_providers/";
        Log.d(TAG, "requestCpps() -- url=" + url);
        xhr.get(url, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            cb(error);
          } else {
            Log.d(TAG, "requestCpps() -- data=" + JSON.stringify(data));
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestCplByCppID(id, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getApiUrl() + "cash_pickup_providers/" + id + "/locations/";
        Log.d(TAG, "requestCplByCppID() -- url=" + url);
        xhr.get(url, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            cb(error);
          } else {
            Log.d(TAG, "requestCplByCppID() -- data=" + JSON.stringify(data));
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestRecipientByID(id, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getApiUrl() +
          "beneficiaries/" +
          id +
          "/?include=country,bank,branch,branch_state,branch_city,cash_pickup_provider,cash_pickup_location,mobile_money_provider,branch_district";
        Log.d(TAG, "requestRecipientByID() -- url=" + url);
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  static requestMMPByID(id, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "mobile_money_providers/" + id + "/";
        Log.d(TAG, "requestMMPByID() -- url=" + url);
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  static requestTransactionByID(id, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getApiUrl() +
          "transactions/" +
          id +
          "/?include=crs,sender,beneficiary,payment_method";
        Log.d(TAG, "requestTransactionByID() -- url=" + url);
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  static requestNotifications(access, cb) {
    ServerData.checkConfig(manageUrls.getBaseApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getBaseApiUrl() +
          "firebase/v1/inapp/?skip=0&count=30&sortType=desc&sortBy=createdDate"
        Log.d(TAG, "requestNotifications() -- url=" + url);
        ServerData.requestAPIWithAccessAuthHeaders(url, access, cb);
      }
    });
  }

  static requestBuyReferralVoucher(access, payload, cb) {
    ServerData.checkConfig(manageUrls.getBaseApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        const url =
          manageUrls.getApiUrl() +
          "referrals/customer/buy/voucher/";
        const headers = Helper.getHeaders(access);
        Log.d(TAG, "requestReferrals() -- url=" + url);
        xhr.post(url, payload, headers, (error, data) => {
          if (error) {
            Log.d(TAG, "requestBuyReferralVoucher() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestRemitHopeTransactionRequest(access, payload, cb) {
    ServerData.checkConfig(manageUrls.getBaseApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        const url =
          manageUrls.getApiUrl() +
          "remit_hope/transactions/?include=crs,payment_method,product,sender";
        const headers = Helper.getHeaders(access);
        Log.d(TAG, "requestRemitHopeCalculate() -- url=" + url);
        // xhr.post(url, payload, headers, (error, data) => {
        //   if (error) {
        //     Log.d(TAG, "requestRemitHopeCalculate() -- error=" + error);
        //     cb(error);
        //   } else {
        //     if (data.hasOwnProperty("errors")) {
        //       cb(ServerData.joinErrorMessages(data, true));
        //     } else {
        //       cb(null, data);
        //     }
        //   }
        // });
        const method = "POST";
        xhr.requestWithBodyRedirect(
          method,
          url,
          payload,
          headers,
          (error, data) => {
            if (error) {
              Log.d(TAG, "requestTransaction() -- error=" + error);
              cb(error);
            } else {
              if (data.hasOwnProperty("redirect")) {
                cb(null, data);
              } else {
                if (data.hasOwnProperty("errors")) {
                  cb(ServerData.joinErrorMessages(data, true));
                } else {
                  cb(null, data);
                }
              }
            }
          }
        );
      }
    });
  }

  static requestRemitHopeCalculate(access, payload, cb) {
    ServerData.checkConfig(manageUrls.getBaseApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        const url =
          manageUrls.getApiUrl() +
          "remit_hope/calculate/";
        const headers = Helper.getHeaders(access);
        Log.d(TAG, "requestRemitHopeCalculate() -- url=" + url);
        xhr.post(url, payload, headers, (error, data) => {
          if (error) {
            Log.d(TAG, "requestRemitHopeCalculate() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestRemitHopeCampaigns(access, id, cb) {
    ServerData.checkConfig(manageUrls.getBaseApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        const headers = Helper.getAuthHeaders(access);
        const url = `${config.remit_hope_base_url}products/?category=${id}`;
        
        xhr.get(url, headers, (error, data) => {
          if (error) {
            cb(error);
          } else {
            Log.d(TAG, "requestRemitHopeCampaigns() -- data=" + JSON.stringify(data));
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestRemitHopeProductDetails(access, cb) {
    ServerData.checkConfig(manageUrls.getBaseApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        const headers = Helper.getHeaders(access);
        const url = `${manageUrls.getApiUrl()}remit_hope/product/?include=sending_currencies,receiving_currencies,payment_methods`;
        
        xhr.get(url, headers, (error, data) => {
          if (error) {
            cb(error);
          } else {
            Log.d(TAG, "requestRemitHopeProductDetails() -- data=" + JSON.stringify(data));
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestRemitHopeCategories(access, cb) {
    ServerData.checkConfig(manageUrls.getBaseApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        const headers = Helper.getAuthHeaders(access);
        const url = `${config.remit_hope_base_url}products/categories/`;

        xhr.get(url, headers, (error, data) => {
          if (error) {
            cb(error);
          } else {
            Log.d(TAG, "requestRemitHopeCategories() -- data=" + JSON.stringify(data));
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestBuyReferralAirtimeDataBundle(access, payload, cb) {
    ServerData.checkConfig(manageUrls.getBaseApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        const url =
          manageUrls.getApiUrl() +
          "referrals/customer/airtime_data_transactions/?include=product,sending_currency";
        const headers = Helper.getHeaders(access);
        Log.d(TAG, "requestBuyReferralAirtimeDataBundle() -- url=" + url);
        xhr.post(url, payload, headers, (error, data) => {
          if (error) {
            Log.d(TAG, "requestBuyReferralAirtimeDataBundle() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestReferrals(access, cb) {
    ServerData.checkConfig(manageUrls.getBaseApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        const url =
          manageUrls.getApiUrl() +
          "referrals/customer/referrals/"
        Log.d(TAG, "requestReferrals() -- url=" + url);
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  static requestCommissions(access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        const url =
          manageUrls.getApiUrl() +
          "referrals/customer/commissions/"
        Log.d(TAG, "requestCommissions() -- url=" + url);
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  static requestCampaigns(access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        const url =
          manageUrls.getApiUrl() +
          "referrals/customer/campaigns/"
        Log.d(TAG, "requestCampaigns() -- url=" + url);
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }
  
  static requestReferralsAirtimeDataProducts(access, mobileNumber, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        const url =
          manageUrls.getApiUrl() +
          `referrals/customer/airtime_data_products/?filter[mobile]=${mobileNumber}&include=currency`
        Log.d(TAG, "requestReferralsAirtimeDataProducts() -- url=" + url);
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  static requestUnreadNotificationsCount(access, cb) {
    ServerData.checkConfig(manageUrls.getBaseApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getBaseApiUrl() +
          "firebase/v1/inapp/count/"
        Log.d(TAG, "requestNotificationCount() -- url=" + url);
        ServerData.requestAPIWithAccessAuthHeaders(url, access, cb);
      }
    });
  }
  
  static updateReadNotifications(payload, access, cb) {
    ServerData.checkConfig(manageUrls.getBaseApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getBaseApiUrl() +
          "firebase/v1/inapp/"
          let method = "PUT";
          const headers = Helper.getAuthHeaders(access)
          xhr.requestWithBody(
            method,
            url,
            payload,
            headers,
            (error, data) => {
              if (error) {
                Log.d(TAG, "putReadNotifications() -- error=" + error);
                cb(error);
              } else {
                if (data.hasOwnProperty("errors")) {
                  cb(ServerData.joinErrorMessages(data, true));
                } else {
                  cb(null, data);
                }
              }
            });
      }
    });
  }

  static requestAirtimeDataTransactionByID(id, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let endpoint = access ? 'airtime_data_transactions/' : 'transactions/';
        let url =
          manageUrls.getApiUrl() +
          endpoint +
          id +
          "/?include=payment_method,product,product.service,sending_currency";
        Log.d(TAG, "requestTransactionByID() -- url=" + url);
        if (access) {
          ServerData.requestAPIWithAccess(url, access, cb);
        } else {
          ServerData.requestAPIWithNoAccess(url, cb);
        }
      }
    });
  }

  static requestCalculationByID(id, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "calculations/" + id + "/";
        Log.d(TAG, "requestCalculationByID() -- url=" + url);
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  // TODO: fix access token 
  static patchTransactionStatusUpdate(payload, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        // handle patch here
        let url = manageUrls.getApiUrl() + "transactions/" + payload.id + "/cancel/";
        let method = "PATCH";
        const headers = Helper.getHeaders(access)
        xhr.requestWithBody(
          method,
          url,
          { data: payload },
          headers,
          (error, data) => {
            if (error) {
              Log.d(TAG, "patchTransactionStatusUpdate() -- error=" + error);
              cb(error);
            } else {
              if (data.hasOwnProperty("errors")) {
                cb(ServerData.joinErrorMessages(data, true));
              } else {
                cb(null, data);
              }
            }
          });
      }
    })
  }

  static patchRequestCalculationByID(id, payload, accessToken, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "calculations/" + id + "/promocode/";
        Log.d(TAG, "requestCalculationRequest() -- url=" + url);
        // const accessToken = cookieHelper.get("access");
        const headers = Helper.getHeaders(accessToken)
        // ? Helper.getHeaders(accessToken)
        // : Helper.getNoTokenHeaders();
        xhr.patch(url, payload, headers, (error, data) => {
          if (error) {
            Log.d(TAG, "requestCalculationRequest() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestRecalculate(id, payload, token, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "calculations/" + id + "/recalculate/";
        Log.d(TAG, "requestRecalculateRequest() -- url=" + url);
        // const accessToken = cookieHelper.get("access");
        const headers = token
          // ? Helper.getHeaders(accessToken)
          ? Helper.getHeaders(token)
          : Helper.getNoTokenHeaders();
        xhr.patch(url, payload, headers, (error, data) => {
          if (error) {
            Log.d(TAG, "requestRecalculateRequest() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestManageBeneficiary(id, payload, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        var url = manageUrls.getApiUrl() + "beneficiaries/";
        var method = "POST";

        if (id) {
          url = manageUrls.getApiUrl() + "beneficiaries/" + id + "/";
          method = "PATCH";
          Log.d(TAG, "requestManageBeneficiary() -- patch");
        } else {
          Log.d(TAG, "requestManageBeneficiary() -- post");
        }

        Log.d(
          TAG,
          "requestManageBeneficiary() -- url=" + url + " method=" + method
        );
        Log.d(
          TAG,
          "requestManageBeneficiary() -- payload=" + JSON.stringify(payload)
        );
        xhr.requestWithBody(
          method,
          url,
          payload,
          Helper.getHeaders(access),
          (error, data) => {
            if (error) {
              Log.d(TAG, "requestManageBeneficiary() -- error=" + error);
              cb(error);
            } else {
              Log.d(
                TAG,
                "requestManageBeneficiary() -- data=" + JSON.stringify(data)
              );
              if (data.hasOwnProperty("errors")) {
                cb(ServerData.joinErrorMessages(data, true));
              } else {
                cb(null, data);
              }
            }
          }
        );
      }
    });
  }

  static deleteRecipient(id, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        Log.d(TAG, "deleteRecipient() -- called ..");
        let url = manageUrls.getApiUrl() + "beneficiaries/" + id + "/";
        xhr.delete(url, Helper.getHeaders(access), error => {
          if (error) {
            Log.d(TAG, "deleteRecipient() -- error=" + error);
            cb(error);
          } else {
            Log.d(TAG, "deleteRecipient() -- success");
            cb(null);
          }
        });
      }
    });
  }

  static requestAccountUpdate(userID, access, payload, password, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        var url = manageUrls.getApiUrl() + "accounts/" + userID + "/";
        var method = "PATCH";
        if (password) {
          url = manageUrls.getApiUrl() + "accounts/" + userID + "/password/";
          method = "PUT";
        }
        Log.d(TAG, "requestAccountUpdate() -- url=" + url);
        Log.d(
          TAG,
          "requestAccountUpdate() -- payload=" + JSON.stringify(payload)
        );
        Log.d(TAG, "requestAccountUpdate() -- method=" + method);
        xhr.requestWithBody(
          method,
          url,
          payload,
          Helper.getHeaders(access),
          (error, data) => {
            if (error) {
              Log.d(TAG, "requestAccountUpdate() -- error=" + error);
              cb(error);
            } else {
              Log.d(
                TAG,
                "requestAccountUpdate() -- data=" + JSON.stringify(data)
              );
              if (data.hasOwnProperty("errors")) {
                cb(ServerData.joinErrorMessages(data, true));
              } else {
                cb(null, data);
              }
            }
          }
        );
      }
    });
  }

  static requestUpdatePersonalDetails(userID, access, payload, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        var url = manageUrls.getApiUrl() + "accounts/" + userID + "/personal-details/";
        var method = "PUT";
        
        Log.d(TAG, "requestUpdatePersonalDetails() -- url=" + url);
        Log.d(
          TAG,
          "requestUpdatePersonalDetails() -- payload=" + JSON.stringify(payload)
        );
        Log.d(TAG, "requestUpdatePersonalDetails() -- method=" + method);
        xhr.requestWithBody(
          method,
          url,
          payload,
          Helper.getHeaders(access),
          (error, data) => {
            if (error) {
              Log.d(TAG, "requestUpdatePersonalDetails() -- error=" + error);
              cb(error);
            } else {
              Log.d(
                TAG,
                "requestUpdatePersonalDetails() -- data=" + JSON.stringify(data)
              );
              if (data.hasOwnProperty("errors")) {
                cb(ServerData.joinErrorMessages(data, true));
              } else {
                cb(null, data);
              }
            }
          }
        );
      }
    });
  }

  static requestUpdateAddressDetails(userID, access, payload, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        var url = manageUrls.getApiUrl() + "accounts/" + userID + "/address-details/";
        var method = "PUT";
        
        Log.d(TAG, "requestUpdateAddressDetails() -- url=" + url);
        Log.d(
          TAG,
          "requestUpdateAddressDetails() -- payload=" + JSON.stringify(payload)
        );
        Log.d(TAG, "requestUpdateAddressDetails() -- method=" + method);
        xhr.requestWithBody(
          method,
          url,
          payload,
          Helper.getHeaders(access),
          (error, data) => {
            if (error) {
              Log.d(TAG, "requestUpdateAddressDetails() -- error=" + error);
              cb(error);
            } else {
              Log.d(
                TAG,
                "requestUpdateAddressDetails() -- data=" + JSON.stringify(data)
              );
              if (data.hasOwnProperty("errors")) {
                cb(ServerData.joinErrorMessages(data, true));
              } else {
                cb(null, data);
              }
            }
          }
        );
      }
    });
  }

  static requestPCAPredictAddressFind(text, container, country, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        var commandContainer = "";
        if (container) {
          commandContainer = "&Container=" + container;
        }

        let url =
          manageUrls.getProxyUrl() +
          "Find/v1.00/json3.ws?Text=" +
          text +
          "&Countries=" +
          country +
          commandContainer +
          "&Limit=10";
        Log.d(TAG, "requestPCAPredictAddressFind() -- url=" + url);
        xhr.get(url, {}, (error, data) => {
          if (error) {
            cb(error);
          } else {
            //ServerData.processPCAAddress(data, cb);
            //cb(null, data);
            if (data.Items.length > 0) {
              cb(null, data.Items);
            } else {
              cb("No address information retrieved");
            }
          }
        });
      }
    });
  }

  static requestPCAPredictAddressRetrieve(id, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getProxyUrl() + "Retrieve/v1.00/json3.ws?Id=" + id;
        Log.d(TAG, "requestPCAPredictAddressRetrieve() -- url=" + url);
        xhr.get(url, {}, (error, data) => {
          if (error) {
            cb(error);
          } else {
            Log.d(
              TAG,
              "requestPCAPredictAddressRetrieve() -- data=" +
              JSON.stringify(data)
            );
            if (data.Items.length > 0) {
              var firstValue = data.Items[0];
              if (firstValue.Error) {
                cb(
                  "" + firstValue.Description + " - Cause: " + firstValue.Cause
                );
              } else {
                cb(null, firstValue);
              }
            } else {
              cb("No address information retrieved");
            }
            //ServerData.processPCAAddress(data, cb);
          }
        });
      }
    });
  }

  static processPCAAddress(data, cb) {
    Log.d(TAG, "processPCAAddress() -- data=" + JSON.stringify(data));
    if (data.hasOwnProperty("errors")) {
      var errorMessages = [];
      for (var i = 0; i < data.errors; i++) {
        errorMessages.push(data.errors[i].Description);
      }
      cb(errorMessages.join(", "));
    } else {
      if (data.data.length > 0) {
        cb(null, data.data);
      } else {
        cb("No address loaded");
      }
    }
  }

  static requestAPIWithAccess(url, access, cb) {
    xhr.get(url, Helper.getHeaders(access), (error, data) => {
      if (error) {
        cb(error);
      } else {
        Log.d(TAG, "requestAPIWithAccess() -- data=" + JSON.stringify(data));
        if (data.hasOwnProperty("errors")) {
          cb(ServerData.joinErrorMessages(data, true));
        } else {
          cb(null, data);
        }
      }
    });
  }

  static requestAPIWithAccessAuthHeaders(url, access, cb) {
    xhr.get(url, Helper.getAuthHeaders(access), (error, data) => {
      if (error) {
        cb(error);
      } else {
        Log.d(TAG, "requestAPIWithAccessAuthHeaders() -- data=" + JSON.stringify(data));
        if (data.hasOwnProperty("errors")) {
          cb(ServerData.joinErrorMessages(data, true));
        } else {
          cb(null, data);
        }
      }
    });
  }

  static requestAPIWithNoAccess(url, cb) {
    xhr.get(url, Helper.getNoTokenHeaders(), (error, data) => {
      if (error) {
        cb(error);
      } else {
        Log.d(TAG, "requestAPIWithAccess() -- data=" + JSON.stringify(data));
        if (data.hasOwnProperty("errors")) {
          cb(ServerData.joinErrorMessages(data, true));
        } else {
          cb(null, data);
        }
      }
    });
  }

  static requestFileUpload(url, formData, token, cb) {
    ServerData.checkConfig(manageUrls.getUploadUrl(), error => {
      if (error) {
        cb(error);
      } else {
        Log.d(TAG, "requestFileUpload() -- url=" + url);
        for (var pair of formData.entries()) {
          Log.d(
            TAG,
            "requestFileUpload() -- payload=" + pair[0] + ": " + pair[1]
          );
        }

        Log.d(
          TAG,
          "requestFileUpload() -- header=" +
          JSON.stringify(Helper.getFormDataHeaders(token))
        );
        xhr.postFile(
          url,
          formData,
          Helper.getFormDataHeaders(token),
          (error, data) => {
            if (error) {
              Log.d(TAG, "requestFileUpload() -- error=" + error);
              cb(error);
            } else {
              Log.d(TAG, "requestFileUpload() -- data=" + JSON.stringify(data));
              if (data.hasOwnProperty("errors")) {
                Log.d(TAG, "requestFileUpload() -- has errors");
                cb(ServerData.joinErrorMessages(data, false));
              } else {
                Log.d(TAG, "requestFileUpload() -- has no errors");
                cb(null, data);
              }
            }
          }
        );
      }
    });
  }

  static requestProductsAndServices(
    benCountryAlphaCode,
    myCountryAlphaCode,
    cb
  ) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getApiUrl() +
          "products/available/" +
          myCountryAlphaCode +
          "/" +
          benCountryAlphaCode +
          "/?include=group";
        Log.d(TAG, "requestProductsAndServices() -- url=" + url);
        xhr.get(url, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestProductsAndServices() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestProductsAndServicesWithFilter(
    benCountryAlphaCode,
    myCountryAlphaCode,
    filter,
    cb
  ) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getApiUrl() +
          "products/available/" +
          myCountryAlphaCode +
          "/" +
          benCountryAlphaCode +
          "/" +
          filter;
        Log.d(TAG, "requestProductsAndServicesWithFilter() -- url=" + url);
        xhr.get(url, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestProductsAndServicesWithFilter() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestProductsDetails(
    id,
    token,
    benCountryAlphaCode,
    myCountryAlphaCode,
    cb
  ) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getApiUrl() +
          "products/available/" +
          myCountryAlphaCode +
          "/" +
          benCountryAlphaCode +
          "/" +
          id +
          "/?include=receiving_currencies,sending_currencies,payment_methods";
        Log.d(TAG, "requestProductsDetails() -- url=" + url);
        var header = Helper.getNoTokenHeaders();
        if (token) {
          Log.d(TAG, "requestProductsDetails() -- has token");
          header = Helper.getHeaders(token);
        }
        xhr.get(url, header, (error, data) => {
          if (error) {
            Log.d(TAG, "requestProductsDetails() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestAirtimeDataProducts(
    mobileNumber,
    token,
    cb
  ) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url =
          manageUrls.getApiUrl() +
          "airtime_data_products/?filter[mobile]=" + mobileNumber + "&include=currency";
        Log.d(TAG, "requestAirtimeDataProducts() -- url=" + url);
        let header = token ? Helper.getHeaders(token) : Helper.getNoTokenHeaders();
        xhr.get(url, header, (error, data) => {
          if (error) {
            Log.d(TAG, "requestAirtimeDataProducts() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestCalculationRequest(payload, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "calculate/";
        Log.d(TAG, "requestCalculationRequest() -- url=" + url);
        const accessToken = cookieHelper.get("access");
        const headers = accessToken
          ? Helper.getHeaders(accessToken)
          : Helper.getNoTokenHeaders();
        xhr.post(url, payload, headers, (error, data) => {
          if (error) {
            Log.d(TAG, "requestCalculationRequest() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestCalculation(payload, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "calculations/";
        Log.d(TAG, "requestCalculation() -- url=" + url);
        xhr.post(url, payload, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestCalculation() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestTransaction(payload, access, transactionID, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        var url = manageUrls.getApiUrl() + "transactions/";
        var method = "POST";
        if (transactionID) {
          url = manageUrls.getApiUrl() + "transactions/" + transactionID + "/";
          method = "PATCH";
        }
        Log.d(TAG, "requestTransaction() -- url=" + url + " method=" + method);

        let headers = Helper.getHeaders(access);
        xhr.requestWithBodyRedirect(
          method,
          url,
          payload,
          headers,
          (error, data) => {
            if (error) {
              Log.d(TAG, "requestTransaction() -- error=" + error);
              cb(error);
            } else {
              if (data.hasOwnProperty("redirect")) {
                cb(null, data);
              } else {
                if (data.hasOwnProperty("errors")) {
                  cb(ServerData.joinErrorMessages(data, true));
                } else {
                  cb(null, data);
                }
              }
            }
          }
        );
      }
    });
  }

  static requestAirtimeDataTransaction(payload, transactionID, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        var url = manageUrls.getApiUrl() + "airtime_data_transactions/?include=payment_method,product";
        var method = "POST";
        // if (transactionID) {
        //   url = manageUrls.getApiUrl() + "transactions/" + transactionID + "/";
        //   method = "PATCH";
        // }
        Log.d(TAG, "requestTransaction() -- url=" + url + " method=" + method);

        let headers = Helper.getNoTokenHeaders();
        xhr.requestWithBodyRedirect(
          method,
          url,
          payload,
          headers,
          (error, data) => {
            if (error) {
              Log.d(TAG, "requestTransaction() -- error=" + error);
              cb(error);
            } else {
              if (data.hasOwnProperty("redirect")) {
                cb(null, data);
              } else {
                if (data.hasOwnProperty("errors")) {
                  cb(ServerData.joinErrorMessages(data, true));
                } else {
                  cb(null, data);
                }
              }
            }
          }
        );
      }
    });
  }

  static requestResetPassword(payload, uid, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "email/passwords/" + uid + "/";
        Log.d(TAG, "requestResetPassword() -- url=" + url);
        xhr.put(url, payload, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestResetPassword() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestCardDetails(id, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "cards/" + id + "/";
        Log.d(TAG, "requestCardDetails() -- url=" + url);
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  static requestLimit(id, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "accounts/" + id + "/limits/";
        Log.d(TAG, "requestLimit() -- url=" + url);
        ServerData.requestAPIWithAccess(url, access, cb);
      }
    });
  }

  static deleteCard(id, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        Log.d(TAG, "deleteCard() -- called ..");
        let url = manageUrls.getApiUrl() + "cards/" + id + "/";
        xhr.delete(url, Helper.getHeaders(access), error => {
          if (error) {
            Log.d(TAG, "deleteCard() -- error=" + error);
            cb(error);
          } else {
            Log.d(TAG, "deleteCard() -- success");
            cb(null);
          }
        });
      }
    });
  }

  static requestCurrencyDetails(id, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "currencies/" + id + "/";
        Log.d(TAG, "requestCurrencyDetails() -- url=" + url);
        xhr.get(url, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestCurrencyDetails() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestApplyPromoCode(payload, id, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "calculations/" + id + "/promocode/";
        Log.d(TAG, "requestApplyPromoCode() -- url=" + url);
        xhr.patch(url, payload, Helper.getHeaders(access), (error, data) => {
          if (error) {
            Log.d(TAG, "requestApplyPromoCode() -- error=" + error);
            cb(error);
          } else {
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestPhoneConfirmation(payload, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "phones/";
        xhr.post(url, payload, Helper.getHeaders(access), (error, data) => {
          if (error) {
            Log.d(TAG, "requestPhoneConfirmation() -- error=" + error);
            cb(error);
          } else {
            Log.d(
              TAG,
              "requestPhoneConfirmation() -- data=" + JSON.stringify(data)
            );
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestSubmitCode(payload, access, id, phoneConfirmationID, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "phones/" + phoneConfirmationID + "/";
        Log.d(TAG, "requestSubmitCode() -- url=" + url);
        xhr.put(url, payload, Helper.getHeaders(access), (error, data) => {
          if (error) {
            Log.d(TAG, "requestSubmitCode() -- error=" + error);
            cb(error);
          } else {
            Log.d(TAG, "requestSubmitCode() -- data=" + JSON.stringify(data));
            if (data.hasOwnProperty("errors")) {
              Log.d(TAG, "requestSubmitCode() -- has error");
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              Log.d(TAG, "requestSubmitCode() -- has NO error");
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestEmailConfirmation(payload, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "emails/";
        xhr.post(url, payload, Helper.getHeaders(access), (error, data) => {
          if (error) {
            Log.d(TAG, "requestEmailConfirmation() -- error=" + error);
            cb(error);
          } else {
            Log.d(
              TAG,
              "requestEmailConfirmation() -- data=" + JSON.stringify(data)
            );
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestSubmitSendingLimitSelection(payload, access, customerCode, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "accounts/" + customerCode + "/product_change_requests/";
        xhr.post(url, payload, Helper.getHeaders(access), (error, data) => {
          if (error) {
            Log.d(TAG, "requestSubmitSendingLimitSelection() -- error=" + error);
            cb(error);
          } else {
            Log.d(
              TAG,
              "requestSubmitSendingLimitSelection() -- data=" + JSON.stringify(data)
            );
            if (data.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestSubmitEmailCode(payload, id, emailConfirmationID, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        let url = manageUrls.getApiUrl() + "emails/" + id + "/";
        Log.d(TAG, "requestSubmitEmailCode() -- url=" + url);
        xhr.put(url, payload, Helper.getNoTokenHeaders(), (error, data) => {
          if (error) {
            Log.d(TAG, "requestSubmitEmailCode() -- error=" + error);
            cb(error);
          } else {
            Log.d(
              TAG,
              "requestSubmitEmailCode() -- data=" + JSON.stringify(data)
            );
            if (data.hasOwnProperty("errors")) {
              Log.d(TAG, "requestSubmitCode() -- has error");
              cb(ServerData.joinErrorMessages(data, true));
            } else {
              Log.d(TAG, "requestSubmitEmailCode() -- has NO error");
              cb(null, data);
            }
          }
        });
      }
    });
  }

  static requestAgents(url, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        Log.d(TAG, "requestAgents() -- url=" + url);
        xhr.get(url, Helper.getNoTokenHeaders(), (error, response) => {
          if (error) {
            Log.d(TAG, "requestAgents() -- error=" + error);
            cb(error);
          } else {
            if (response.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(response, true));
            } else {
              Log.d(
                TAG,
                "requestAgents() -- response=" + JSON.stringify(response)
              );
              cb(null, response);
            }
          }
        });
      }
    });
  }

  static requestCashPoints(url, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        Log.d(TAG, "requestCashPoints() -- url=" + url);
        xhr.get(url, Helper.getNoTokenHeaders(), (error, response) => {
          if (error) {
            Log.d(TAG, "requestCashPoints() -- error=" + error);
            cb(error);
          } else {
            if (response.hasOwnProperty("errors")) {
              cb(ServerData.joinErrorMessages(response, true));
            } else {
              Log.d(
                TAG,
                "requestCashPoints() -- response=" + JSON.stringify(response)
              );
              cb(null, response);
            }
          }
        });
      }
    });
  }

  static requestManageBWBeneficiary(id, bwId, payload, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        var url =
          manageUrls.getApiUrl() + "accounts/" + id + "/breadwinner_beneficiaries/";
        var method = "POST";

        if (bwId) {
          url =
            manageUrls.getApiUrl() +
            "accounts/" +
            id +
            "/breadwinner_beneficiaries/" +
            bwId +
            "/";
          method = "PATCH";
          Log.d(TAG, "requestManageBWBeneficiary() -- patch");
        } else {
          Log.d(TAG, "requestManageBWBeneficiary() -- post");
        }

        Log.d(
          TAG,
          "requestManageBWBeneficiary() -- url=" + url + " method=" + method
        );
        Log.d(
          TAG,
          "requestManageBWBeneficiary() -- payload=" + JSON.stringify(payload)
        );
        xhr.requestWithBody(
          method,
          url,
          payload,
          Helper.getHeaders(access),
          (error, data) => {
            if (error) {
              Log.d(TAG, "requestManageBWBeneficiary() -- error=" + error);
              cb(error);
            } else {
              Log.d(
                TAG,
                "requestManageBWBeneficiary() -- data=" + JSON.stringify(data)
              );
              if (data.hasOwnProperty("errors")) {
                cb(ServerData.joinErrorMessages(data, true));
              } else {
                cb(null, data);
              }
            }
          }
        );
      }
    });
  }

  static deleteBWBeneficiary(id, bwId, index, total, access, cb) {
    ServerData.checkConfig(manageUrls.getApiUrl(), error => {
      if (error) {
        cb(error);
      } else {
        Log.d(TAG, "deleteBWBeneficiary() -- called ..");
        let url =
          manageUrls.getApiUrl() +
          "accounts/" +
          id +
          "/breadwinner_beneficiaries/" +
          bwId + "/";
        xhr.delete(url, Helper.getHeaders(access), error => {
          if (error) {
            Log.d(TAG, "deleteBWBeneficiary() -- error=" + error);
            cb(error);
          } else {
            Log.d(TAG, "deleteBWBeneficiary() -- success");
            cb(null, index, total);
          }
        });
      }
    });
  }

  static joinErrorMessages(data, hasErrors) {
    var errorMessageStack = [];
    if (hasErrors) {
      var dataErrors = data.errors;
      Log.d(
        TAG,
        "joinErrorMessages() -- dataErrors=" + JSON.stringify(dataErrors)
      );
      for (var i = 0; i < dataErrors.length; i++) {
        var error = dataErrors[i];
        Log.d(
          TAG,
          "joinErrorMessages() -- error=" +
          JSON.stringify(error) +
          " index=" +
          i
        );
        if (process.env.NODE_ENV === "development") {
          errorMessageStack.push(
            "Code: " +
            error.status +
            " on field " +
            error.source.pointer.substring(
              error.source.pointer.lastIndexOf("/") + 1,
              error.source.pointer.length
            ) +
            " - Detail: " +
            error.detail
          );
        } else {
          if (error.code.indexOf("password") !== -1) {
            let errorMsg = `Password Error: ${error.detail}`;
            errorMessageStack.push(errorMsg);
          } else {
            errorMessageStack.push(error.detail);
          }
        }
      }
      return errorMessageStack.join(", ");
    } else {
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          var message = "";
          if (process.env.NODE_ENV === "development") {
            message = key + ": " + JSON.stringify(data[key]);
          } else {
            message = JSON.stringify(data[key]);
          }
          errorMessageStack.push(message);
        }
      }
      return errorMessageStack.join(", ");
    }
  }

  static checkConfig(url, cb) {
    if (!url) {
      cb("No config URL provided");
    } else {
      cb(null);
    }
  }
}

export default ServerData;
